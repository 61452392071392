.carousel {
  .carousel__item {
    .event-logo-animation {
      position: relative;
      flex: 1;
    }
    .event-content {
      height: 100%;
      width: 100%;

      background-repeat: no-repeat;
      background-size: cover;
      background-position: center center;

      display: flex;
      flex-direction: column-reverse;
      text-align: center;
      &__container {
        flex-direction: column-reverse;
      }
      &__background {
        max-height: 100%;
      }

      .logo-img {
        position: relative;
        width: calc(100% - ($spacer-4-value - $spacer-1-value)) !important;
        margin: 0 auto ($spacer-1-value - $spacer-1-value);
        img {
          position: relative !important;
        }
      }

      h6 {
        margin: 0;
        position: relative; // Make sure it pops over shadow with absolute
        font-weight: $dm-sans-weight-bold;
        bottom: 0;
        white-space: normal;
        font-size: 11px;
      }

      .event__card-subline {
        position: relative; // Make sure it pops over shadow with absolute
        align-items: center;
        font-size: 11px;
        line-height: $caption-text-line-height;
        margin-bottom: 0 !important;

        i {
          position: relative;
          top: -1px;
          margin-right: 8px;

          &:before {
            color: $text-primary;
            font-size: 16px;
          }
        }
      }

      &__short-text {
        @include caption-title();
        white-space: pre-wrap;
        position: relative;
        text-align: center;
        width: 100%;
        opacity: 1;
        animation: fadeAnimation 0.6s ease;
        text-shadow: 0px 1px 2px rgba(18, 17, 18, 0.75);
      }

      &__short-text-no-date {
        @extend .event-content__short-text;
        position: absolute;
        left: 50%;
        bottom: 15px;
        width: 90%;
        transform: translate(-50%, 0);
        text-shadow: 0px 1px 2px rgba(18, 17, 18, 0.75);
      }
    }
  }
}

@keyframes fadeAnimation {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 1;
  }
}

@include media-breakpoint-up(md) {
  .carousel .carousel__wrapper .carousel__items {
    .carousel__item {
      .event-content {
        .logo-img {
          width: calc(100% - ($spacer-4-value - $spacer-1-value)) !important;
          max-height: 56px;
          height: 100%;
          margin: 0 auto ($spacer-1-value - $spacer-1-value);
        }
      }
    }
  }
}

@include media-breakpoint-up(lg) {
  .carousel .carousel__wrapper .carousel__items {
    .carousel__item {
      .event-content {
        .logo-img {
          width: calc(100% - ($spacer-6-value - $spacer-2-value)) !important;
          max-height: 126px;
          margin: 0 auto ($spacer-2-value - $spacer-2-value);
        }

        h6 {
          font-size: 13px;
        }

        .event__card-subline {
          margin-top: $spacer-1-value;
          font-size: 13px;
          line-height: $body-text-line-height;

          i {
            position: relative;
            top: -2px;

            &:before {
              font-size: 24px;
            }
          }
        }

        &__short-text {
          @include body-title;
        }
      }
    }
  }
}

@include media-breakpoint-up(xxl) {
  .carousel .carousel__wrapper .carousel__items {
    .carousel__item {
      .event-content {
        .logo-img {
          width: calc(100% - ($spacer-7-value - $spacer-2-value)) !important;
          max-height: 166px;
          margin: 0 auto ($spacer-2-value - $spacer-2-value);
        }
      }
    }
  }
}

@media (hover: none) or (pointer: coarse) {
  .carousel {
    .carousel__item {
      .event-content__short-text {
        display: block !important;
      }
      .hidden-sm {
        display: none !important;
      }
    }
  }
}
