a.skip-main {
  height: auto;
  left: -999px;
  position: fixed;
  top: 10px;
  overflow: hidden;
  z-index: -9999;
}

a.skip-main:focus,
a.skip-main:active {
  left: 8px;
  z-index: 9999;
}
