.color-accent-light,
.color-accent-light.oa::before {
  color: $accent-light;
}

.color-accent-lightest,
.color-accent-lightest.oa::before {
  color: $accent-lightest;
}

.color-background-secondary,
.color-background-secondary.oa::before {
  color: $bg-secondary;
}
