.hero__event-info {
  .event-info__description {
    margin-top: 32px;
    @include caption-text;
  }

  .event-info__details {
    margin-top: 32px;
    justify-content: center;
    white-space: nowrap;

    .event-info__title {
      color: $shade-3;
      font-weight: 400;
      display: inline-block;
      margin-bottom: 8px;
    }
    .event-info__price-from {
      background-color: $secondary-midtone;
      font-weight: 700;
      border-radius: 4px;
      padding: 3px;
    }
  }

  .event-info__buttons {
    margin-top: 24px;
    display: flex;
    justify-content: center;
    grid-template-columns: repeat(auto-fit, minmax(170px, 1fr));
    align-items: center;

    .btn-onair {
      justify-self: center;
    }

    a.buy-show-link,
    a.watch-trailer-link {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      flex: 1;
      padding: 12px;

      i {
        margin-right: 8px;

        &:before {
          color: $shade-2;
          font-size: 24px;
          position: relative;
          top: 0px;
        }
      }
    }

    a.watch-trailer-link {
      font-family: var(--font-dm-sans);
      font-weight: $dm-sans-weight-bold;
      border-radius: 32px;
      border: 2px solid transparent;
      white-space: nowrap;
      color: $shade-2;
    }
  }
}

@include media-breakpoint-up(sm) {
  .hero__event-info {
    .event-info__buttons {
      a.buy-show-link,
      a.watch-trailer-link {
        flex: 0.3;
      }
    }
  }
}

@include media-breakpoint-up(md) {
  .hero__event-info {
    .event-info__description {
      max-width: 90%;
    }

    .event-info__details {
      justify-content: left;

      > div {
        margin-right: 32px;
      }
    }

    img.hero__logo {
      max-height: none;
      width: 180px;
      object-fit: contain;
    }

    .event-info__buttons {
      justify-content: flex-start;

      a.buy-show-link,
      a.watch-trailer-link {
        flex: inherit;
        font-size: 16px;
        text-align: center;
        padding: 10px 16px;

        .oa:before {
          top: 0px;
        }
      }

      a.buy-show-link {
        margin-right: 16px;
        padding: 10px 64px;
      }

      .btn-onair {
        justify-self: flex-start;
      }
    }
  }
}

@include media-breakpoint-up(lg) {
  .hero__event-info {
    .event-info__description {
      font-size: 16px;
      line-height: 24px;
    }

    .event-info__buttons {
      a.buy-show-link {
        margin-right: 24px;
      }

      a.watch-trailer-link {
        border: 2px solid $shade-2;
      }

      a.buy-show-link,
      a.watch-trailer-link {
        padding: 10px 38px;
      }
    }
  }
}
@include media-breakpoint-up(xxl) {
  .hero__event-info {
    .event-info__buttons {
      margin-top: 40px;
    }
  }
}
