.youtube-player {
  height: 210px;
}

@include media-breakpoint-up(md) {
  .youtube-player {
    height: 432px;
  }
}

@include media-breakpoint-up(lg) {
  .youtube-player {
    height: 562px;
  }
}

@include media-breakpoint-up(xl) {
  .youtube-player {
    height: 810px;
  }
}
