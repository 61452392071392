.highlights-block {
  background-color: $shade-7;

  padding: $spacer-7-value 0px;

  a {
    @include link-primary();
    font-weight: $dm-sans-weight-regular;
    font-family: var(--font-dm-sans);
  }

  &--show-transparent-background {
    background-color: transparent;
  }

  .container {
    display: grid;

    grid-template-columns: 1fr;
    grid-gap: $spacer-5-value;
  }

  .highlight {
    &__icon {
      height: 64px;
      width: 64px;

      &:before {
        height: 64px;
        width: 64px;
        font-size: 64px;
        background: linear-gradient(
          91.33deg,
          $gradient-orange-begin 0.86%,
          $gradient-orange-end 100%
        );
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }

    &__title {
      @include section-title;
      text-align: center;

      color: $shade-1;
    }

    &__text {
      @include body-text;
      color: $shade-3;

      p {
        margin: $spacer-2-value 0 0;
        text-align: center;
      }
    }
  }
}

@include media-breakpoint-up(sm) {
  .highlights-block {
    .highlight {
      &__title {
        text-align: left;
      }
      &__text {
        p {
          text-align: left;
        }
      }
    }
  }
}

@include media-breakpoint-up(md) {
  .highlights-block {
    .container {
      grid-template-columns: repeat(2, 1fr);
    }
  }
}

@include media-breakpoint-up(lg) {
  .highlights-block {
    padding: $spacer-11-value 0px;

    .container {
      grid-gap: $spacer-8-value;
    }
  }
}
