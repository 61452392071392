:root {
  // PRIMARY COLOR
  --primary-darkest: #{$primary-darkest};
  --primary-dark: #{$primary-dark};
  --primary-midtone: #{$primary-midtone};
  --primary-light: #{$primary-light};
  --primary-lightest: #{$primary-lightest};

  // SECONDARY COLOR
  --secondary-darkest: #{$secondary-darkest};
  --secondary-dark: #{$secondary-dark};
  --secondary-midtone: #{$secondary-midtone};
  --secondary-light: #{$secondary-light};
  --secondary-lightest: #{$secondary-lightest};

  // ACCENT COLOR
  --accent-darkest: #{$accent-darkest};
  --accent-dark: #{$accent-dark};
  --accent-midtone: #{$accent-midtone};
  --accent-light: #{$accent-light};
  --accent-lightest: #{$accent-lightest};

  // ======== FEEDBACK =========

  // SUCCESS COLOR
  --success-darkest: #{$success-darkest};
  --success-dark: #{$success-dark};
  --success-midtone: #{$success-midtone};
  --success-light: #{$success-light};
  --success-lightest: #{$success-lightest};

  // INFO COLOR
  --info-darkest: #{$info-darkest};
  --info-dark: #{$info-dark};
  --info-midtone: #{$info-midtone};
  --info-light: #{$info-light};
  --info-lightest: #{$info-lightest};

  // WARNING COLOR
  --warning-darkest: #{$warning-darkest};
  --warning-dark: #{$warning-dark};
  --warning-midtone: #{$warning-midtone};
  --warning-light: #{$warning-light};
  --warning-lightest: #{$warning-lightest};

  // ERROR COLOR
  --error-darkest: #{$error-darkest};
  --error-dark: #{$error-dark};
  --error-midtone: #{$error-midtone};
  --error-light: #{$error-light};
  --error-lightest: #{$error-lightest};

  // ======== SHADES =========

  // SHADE COLOR
  --shade-black: #{$shade-black};
  --shade-7: #{$shade-7};
  --shade-6: #{$shade-6};
  --shade-5: #{$shade-5};
  --shade-4: #{$shade-4};
  --shade-3: #{$shade-3};
  --shade-2: #{$shade-2};
  --shade-1: #{$shade-1};
  --shade-white: #{$shade-white};

  // ======== OVERLAYS =========

  // BG COLOR
  --bg-transparent-80: #{$bg-transparent-80};
  --bg-transparent-50: #{$bg-transparent-50};
}
