.socials {
  .socials__link-wrapper {
    display: flex;
    flex-wrap: wrap;

    a {
      margin: 0 8px;

      &:first-child {
        margin-left: 0px;
      }
    }

    .oa {
      &:before {
        color: $shade-3;
        transition: color 0.2s ease;
        font-size: 24px;
      }

      &:hover,
      &:focus {
        cursor: pointer;

        &::before {
          color: $shade-2;
        }
      }
    }
  }

  .socials__icon-spacing-fix {
    .oa {
      &:before {
        top: -0.5px;
      }
    }
  }
}
