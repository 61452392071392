// This file uses ID's because OneTrust writes CSS using ID's...

// Delete the little hover over on all pages
.ot-floating-button__front {
  display: none !important;
}

.cookie-policy .row .col-12 {
  #ot-sdk-cookie-policy {
    #ot-sdk-cookie-policy-v2.ot-sdk-cookie-policy.ot-sdk-container {
      width: 100%;
      padding: 0;

      h3 {
        @include title();
      }

      table,
      p,
      div#cookie-policy-description {
        @include body-text();
      }

      .ot-sdk-cookie-policy-group {
        margin-top: $spacer-2-value !important;
        @include title();
      }

      table {
        margin-top: $spacer-2-value !important;

        a {
          background: transparent;
          @include link-primary();
        }
      }
    }
  }
}

@include media-breakpoint-up(lg) {
  .cookie-policy .row .col-12 {
    #ot-sdk-cookie-policy {
      #ot-sdk-cookie-policy-v2.ot-sdk-cookie-policy.ot-sdk-container {
        h3 {
          @include title-lg();
        }

        .ot-sdk-cookie-policy-group {
          @include title-lg();
        }
      }
    }
  }
}
