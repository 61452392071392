.link {
  @include link();

  &.link-primary {
    @include link-primary();

    .oa:before {
      @include link-primary();
    }
  }

  &.link-secondary {
    @include link-secondary();

    .oa:before {
      @include link-secondary();
    }
  }

  &.link-tertiary {
    @include link-tertiary();

    .oa:before {
      @include link-tertiary();
    }
  }
}
