.content-block {
  background-color: $shade-7;

  &--show-transparent-background {
    background-color: transparent;
    height: 100%;
  }

  a {
    @include link-primary();
    font-weight: $dm-sans-weight-regular;
    font-family: var(--font-dm-sans);
  }

  .content-block__call-to-action {
    width: auto;
    font-weight: $dm-sans-weight-bold;
  }

  &__image {
    display: flex;
    flex: 1;
    position: relative;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    &:focus-visible {
      outline: none;

      img {
        @include global-focus-style();
      }
    }
  }

  &__icons {
    display: flex;

    & .oa {
      font-size: 24px;
      &:before {
        color: $shade-1;
      }
      &:not(:last-child) {
        margin-right: 8px;
      }
    }
  }

  .content-block__link-area .link {
    display: flex;

    .oa:before {
      font-size: 32px !important;
    }

    &:hover,
    &:focus {
      color: $btn-solid-secondary-hover-bg;

      .oa:before {
        color: $btn-solid-secondary-hover-bg;
      }
    }

    &:active {
      color: $btn-solid-secondary-pressed-bg;

      .oa:before {
        color: $btn-solid-secondary-pressed-bg;
      }
    }
  }
  .content-block__social-media-title {
    color: $shade-3;
  }

  .description {
    @include body-text;

    p {
      margin: 24px 0;
    }
  }

  .content-block__subtitle {
    p {
      @include body-text;
      line-height: 18px;
      color: $shade-3;
    }
  }

  .content-block__subtitle--offset {
    margin-left: 2px;
  }
}

@include media-breakpoint-up(md) {
  .content-block {
    .content-block__subtitle {
      p {
        line-height: 20px;
      }
    }
  }
}

@include media-breakpoint-down(lg) {
  .content-block {
    .content-block__call-to-action {
      width: 100%;
    }
  }
}

@include media-breakpoint-up(lg) {
  .content-block {
    padding: 56px 0;
  }
}

@include media-breakpoint-down(md) {
  .container--padding-vertical {
    padding: 44px 0;
  }
}

@include media-breakpoint-down(sm) {
  .container--padding-vertical {
    padding: 4px 0;
  }
}
