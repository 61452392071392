.app-store-banner {
  .item {
    min-width: auto;
    margin-right: $spacer-3-value;
  }

  &__icon {
    border-radius: 8px;
  }

  :last-child {
    margin-right: unset;
  }
}
