.side-navigation {
  // Cancel some button specific styling for logout
  button {
    background-color: transparent;
    border: 0;
    text-align: left;
  }

  // Style the buttons/links
  button,
  a {
    display: flex;
    align-items: center;

    user-select: none;
    font-size: 18px;
    line-height: 24px;
    font-family: var(--font-poppins);
    padding: 7px 0;
    margin: 7px 0;
    border-radius: 20px;

    color: $navigation-link-color;

    // The icon that we need to render
    i {
      padding-right: 12px;
      padding-left: 32px;
      font-size: 24px;

      &:before {
        color: $navigation-link-color;
      }
    }

    // The hacked line before the items
    &:before {
      display: block;
      position: absolute;
      content: '';

      background-color: $navigation-link-color;
      width: 2px;
      height: 0px;
      margin: auto;

      transition: height 0.2s ease;
    }

    // Hovering state of the sub items
    &:hover {
      cursor: pointer;

      &:before {
        height: 40px;
      }
    }

    // Active state
    &.link--active {
      font-weight: $poppins-weight-bold;

      &:before {
        height: 40px;
        background-color: $primary-midtone;
      }
    }
  }

  // Only for the logout
  button {
    i:before {
      top: 0px;
      left: 2px;
    }
  }
}
