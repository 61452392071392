// Setting a fix height for the icon to make sure that all the icons and titles are properly aligned
$event-selling-point-icon-height: 48px;
$event-selling-point-icon-size: 48px;
$event-selling-point-top-padding: 50px;
$event-selling-point-icon-top-position: $event-selling-point-top-padding;
$event-selling-point-margin-between-icon-and-title: $event-selling-point-icon-height +
  45px;

.event-selling-points-wrap {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
  grid-gap: $spacer-3-value;
  justify-content: center;
}

.event-selling-point {
  background-color: $shade-7;

  a {
    @include link-primary();
    font-weight: $dm-sans-weight-regular;
    font-family: var(--font-dm-sans);
  }

  i {
    font-size: $event-selling-point-icon-size;
    height: $event-selling-point-icon-height;
    color: $shade-2;

    &:before {
      color: $shade-2;
    }
  }

  p {
    margin: 0;
  }
}

@include media-breakpoint-down(sm) {
  .event-selling-points-wrap {
    grid-gap: $spacer-2-value;
  }
}

@include media-breakpoint-up(lg) {
  .event-selling-points-wrap {
    grid-template-columns: repeat(auto-fit, minmax(216px, 1fr));
  }
}

@include media-breakpoint-up(xl) {
  .event-selling-points-wrap {
    grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
  }
}
