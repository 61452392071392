.file-uploader__wrapper {
  input[type='file'] {
    display: none;
  }
  .custom-file-uploader {
    display: inline-block;
    cursor: pointer;
    > div {
      align-items: center;
      display: flex;
      font-size: 24px;
    }
  }
  .uploaded-file {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px;
    background-color: #58565a;

    .oa {
      &:before {
        font-size: 24px;
        cursor: pointer;
      }
      &:hover:before {
        color: $shade-1;
      }
    }
    div {
      width: 300px;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
