.notification {
  padding: 8px;
  border: 1px solid;
  border-radius: 5px;
  display: flex;

  &__icon-wrapper {
    display: flex;

    i {
      margin-right: 4px;
      font-size: 24px;
    }
  }

  &__text-wrapper {
    text-align: left;
  }

  &.notification--error {
    background-color: $error-lightest;
    border-color: $error-darkest;

    p {
      color: $error-darkest;
    }
    i:before {
      color: $error-darkest;
    }
  }

  &.notification--success {
    background-color: $success-lightest;
    border-color: $success-darkest;

    p {
      color: $success-darkest;
    }
    i:before {
      color: $success-darkest;
    }
  }

  &.notification--info {
    background-color: $info-neutral-dark;
    border-color: $info-neutral-dark;

    p {
      color: $info-neutral-light;
    }

    i:before {
      color: $info-neutral-light;
    }
  }

  &.notification--warning {
    background-color: $warning-lightest;
    border-color: $warning-lightest;

    p {
      color: $warning-darkest;
    }

    i:before {
      color: $warning-darkest;
      top: 0;
    }
  }
}
