.footer {
  border-top: 1px solid $accent-light;
  margin-top: 0px;
  padding-top: 56px;
  padding-bottom: $spacer-7-value;
  background-color: $bg-dark;

  .footer-text {
    @include caption-text;
    line-height: 24px;
    color: $shade-3;
  }

  // If the prop disableTopLine is on the footer
  &.footer--no-topline {
    border-top-width: 0;

    hr.footer__top-border {
      border-top-width: 0;
    }
  }

  hr {
    border: 0px;
    border-top: 1px solid $accent-light;
    margin: 0;

    &.footer__top-border {
      padding-bottom: 112px;
    }
  }

  .footer__menu-group {
    margin-bottom: $spacer-1-value;
    height: 100%;

    ul {
      margin-top: $spacer-1-value;
      list-style-type: none;
      margin: 0;
      padding: 0;

      li {
        line-height: 24px;
        margin: $spacer-2-value 0px;
      }
    }

    .footer-link {
      transition:
        color 0.2s ease,
        text-decoration-color 0.2s ease;
      text-decoration-color: transparent;
      color: $shade-3;
      font-size: 16px;
      font-weight: 400;

      &:hover {
        color: $shade-2;
        text-decoration: underline;
      }
    }

    .footer__logo {
      margin: auto auto 12px;
    }

    .footer__trees {
      p {
        color: $shade-1;
      }
    }

    .footer__menu-heading {
      margin-bottom: $spacer-1-value;

      .footer__title {
        @include body-title;
        font-weight: 300;
        font-size: 22px;
        font-family: var(--font-poppins);
        line-height: 24px;
      }
    }

    .footer__tagline {
      max-width: 80%;
    }

    .footer__logo-row {
      .footer__logo-item {
        min-width: auto;
        margin-right: $spacer-3-value;

        .logo-spacing {
          margin: 4px $spacer-1-value 0 0;
        }
      }

      :last-child {
        margin-right: unset;
      }
    }

    .footer__logo__icon {
      border-radius: 8px;
    }

    .footer__dropdown-container {
      border-radius: 5px;
      min-width: 100%;
      padding: 0px $spacer-2-value;
      box-shadow: inset 0 0 0 1px $shade-3;

      .footer__dropdown-group {
        margin-right: $spacer-1-value;
        height: 40px;
        width: 100%;

        .footer__dropdown-icon {
          font-size: 22px;
          &:before {
            color: #fff;
          }
        }

        .footer__dropdown {
          height: 32px;
          background-color: $bg-dark;
          color: $text-primary;
          box-shadow: none;
          font-size: 14px;

          &:after {
            color: #fff;
            align-items: center;
            font-size: 14px;
            margin-left: $spacer-1-value;
          }
        }
      }
    }
  }

  .footer__menu-links {
    text-align: center;
    margin-bottom: $spacer-3-value;
  }

  .footer__bottombar-copyright {
    hr {
      margin-bottom: 12px;
    }

    text-align: center;
    margin: $spacer-6-value auto 0px;

    .copyright-text {
      @include caption-text;
      line-height: 24px;
      color: $shade-3;
      font-family: var(--font-dm-sans) !important;
      font-size: 13px !important;

      a {
        color: $accent-lightest;
        text-decoration: underline;
      }
    }
  }
}

@include media-breakpoint-up(xs) {
  .footer {
    border-top: none;
    padding-top: $spacer-7-value;

    .footer_logo-locale-group {
      padding: 0px $spacer-1-value;
    }

    .footer__dropdown-container {
      margin: 0;
    }

    .footer__tagline {
      margin: 12px auto;
      text-align: center;
    }

    .footer__socials {
      margin: 56px 0px 12px;
    }

    .footer__section-links {
      margin: $spacer-4-value 0px $spacer-3-value;
    }
  }
}

@include media-breakpoint-up(md) {
  .footer {
    border-top: none;

    .footer__logo-item {
      :first-child {
        margin-right: $spacer-1-value;
      }
    }

    .footer_logo-locale-group {
      margin-bottom: unset;
    }

    .footer__tagline {
      margin: 0px 0px $spacer-1-value;
      text-align: left;
    }

    .footer__socials {
      margin: 0px;
    }

    .footer__menu-links {
      text-align: left;
    }

    .footer__section-links {
      margin-top: unset;
      margin-bottom: $spacer-4-value;
    }
  }
}

@include media-breakpoint-up(lg) {
  .footer {
    border-top: none;
    padding-top: 0;

    .footer__logo-item {
      margin-right: $spacer-2-value;

      :first-child {
        margin-right: $spacer-1-value;
      }
    }

    .footer__section-links {
      margin-top: $spacer-1-value;
    }
  }
}
