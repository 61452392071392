.event-grid {
  position: relative;

  width: 100%;

  display: flex;
  flex-wrap: wrap;

  gap: 24px;

  .carousel__item {
    box-sizing: border-box;
    scroll-snap-align: start;
    transition:
      transform 0.3s ease,
      border-color 0.2s ease;

    flex: 0 0 184px;
    
    border-radius: $carousel-border-radius;
    overflow: hidden;
    // Trick for border-radius to work on macOs devices
    perspective: 1px;
    border: 2px solid transparent;

    object-fit: contain;

    a {
      display: block;
      height: 100%;
      width: 100%;
      aspect-ratio: 2/3;
    }

    &:hover {
      cursor: pointer;
      border-color: $shade-1;
      box-shadow: 0 0 10px $shade-7;
      transform: scale(1.0001);
    }

    .event-content {
      height: 100%;
      width: 100%;

      background-repeat: no-repeat;
      background-size: cover;
      background-position-x: 50%;

      display: flex;
      flex-direction: column-reverse;
      text-align: center;

      &__container {
        padding: 24px;
        margin-bottom: 0px !important;

        flex-direction: column-reverse;

        .event__card-subline {
          display: none;
        }
      }

      .logo-img {
        position: relative;
        height: auto;
        margin: 0 auto ($spacer-1-value - $spacer-1-value);
        display: flex;
        justify-content: center;

        img {
          width: 100% !important;
          max-width: 100% !important;
          max-height: 25%;
          position: relative !important;
        }
      }

      h6 {
        margin: 0;
        position: relative; // Make sure it pops over shadow with absolute
        font-weight: $dm-sans-weight-bold;
        bottom: 0;
        white-space: normal;
        font-size: 11px;
      }

      .event__card-subline {
        position: relative; // Make sure it pops over shadow with absolute
        align-items: center;
        margin-top: 4px;
        font-size: 11px;
        line-height: $caption-text-line-height;
        margin-bottom: 0 !important;

        i {
          position: relative;
          top: -1px;
          margin-right: 8px;

          &:before {
            color: $text-primary;
            font-size: 16px;
          }
        }
      }

      &__short-text {
        @include caption-title();
        white-space: pre-wrap;
        position: relative;
        text-align: center;
        width: 100%;
        opacity: 1;
        animation: fadeAnimation 0.6s ease;
        will-change: opacity, transform;
      }

      &__short-text-no-date {
        @extend .event-content__short-text;
        position: absolute;
        left: 50%;
        bottom: 15px;
        width: 90%;
        transform: translate(-50%, 0);
      }
    }
  }
}

@include media-breakpoint-up(xs) {
  .event-grid {
    gap: 8px;
    width: 100%;

    a {
      min-width: calc(50% - 4px);
      max-width: calc(50% - 4px);
      aspect-ratio: 2/3;
    }

    .carousel__item {
      .event-content {
        &__container {
          padding: 24px 16px !important;
        }
      }
    }
  }
}

@include media-breakpoint-up(md) {
  .event-grid {
    width: 100%;
    gap: 16px;

    a {
      min-width: 218px;
      aspect-ratio: 2/3;
    }

    .carousel__item {
      .event-content {
        &__container {
          padding: 24px !important;
        }
      }
    }
  }
}

@include media-breakpoint-up(lg) {
  .event-grid {
    gap: 24px;

    a {
      min-width: calc(50% - 252px) !important;
      aspect-ratio: 2/3 ;
    }

    .carousel__item {
      .event-content {
        &__short-text {
          font-size: 16px !important;
          line-height: 24px !important;
        }
      }
    }
  }
}

@include media-breakpoint-up(xl) {
  .event-grid {
    gap: 24px;

    a {
      min-width: calc(50% - 202px) !important;
      aspect-ratio: 2/3 ;
    }

    .carousel__item {
      .event-content {
        &__short-text {
          font-size: 16px !important;
          line-height: 24px !important;
        }
      }
    }
  }
}

@include media-breakpoint-up(xxl) {
  .event-grid {
    gap: 24px;

    a {
      min-width: 416px !important;
      aspect-ratio: 2/3 ;
    }

    .carousel__item {
      .event-content {
        &__container {
          padding: 56px 32px !important;
        }

        &__short-text {
          font-size: 16px !important;
          line-height: 24px !important;
        }
      }
    }
  }
}

@include media-breakpoint-up(fullHd) {
  .event-grid {
    gap: 24px;

    a {
      min-width: 416px !important;
      aspect-ratio: 2/3 ;
    }

    .carousel__item {
      .event-content {
        &__container {
          padding: 56px 32px !important;
        }

        &__short-text {
          font-size: 16px !important;
          line-height: 24px !important;
        }
      }
    }
  }
}
