.flex-grow {
  flex-grow: 1;
}

.flex-shrink {
  flex-shrink: 0;
}

.flex-1 {
  flex: 1;
}
