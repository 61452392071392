.w-100 {
  width: 100%;
}

.mw-100 {
  max-width: 100%;
}

.h-100 {
  height: 100%;
}

.mw-80 {
  max-width: 35rem;
}

.mr-n1 {
  margin-right: 20px;
}

.ml-auto {
  margin-left: auto !important;
}
