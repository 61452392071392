.my-place-title {
  margin-bottom: 24px;
}

.my-place-overview {
  .my-place-overview__dark-bg {
    background-color: $bg-dark;
    padding: 32px;
  }

  .ticket {
    margin: 16px 0 24px;
  }

  .my-place-overview__show-section {
    margin: 40px 0 60px;
  }
}

.my-place__request-account-deletion-button i {
  margin-right: 10px !important;
}
