// The 'importantHack' has been added bc otherwise it was impossible to override the OneTrust styles.
// Please don't use this hack if it's absolutely not necessary
@mixin button-base($importantHack: false) {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px 32px;
  margin: 0;
  text-decoration: none;

  border-radius: 32px;
  border: 2px solid transparent if-important($importantHack);

  transition:
    background-color 200ms ease-in-out,
    color 200ms ease-in-out,
    border-color 200ms ease-in-out;

  white-space: nowrap;
  height: max-content; // Otherwise if wrapped in flex button grows in height

  &:hover {
    cursor: pointer;
  }
}

@mixin button-solid-secondary($importantHack: false) {
  background-color: $btn-solid-secondary-bg if-important($importantHack);
  color: $btn-solid-secondary-color if-important($importantHack);

  &:hover {
    background-color: $btn-solid-secondary-hover-bg if-important($importantHack);
  }

  &.btn-disabled {
    color: $disabled-color if-important($importantHack);
    background-color: $disabled-bg if-important($importantHack);

    .oa:before {
      color: $disabled-color if-important($importantHack);
    }
  }

  &:focus {
    // TODO dicuss with Dirk what to do here?
  }

  .oa:before {
    color: $btn-solid-secondary-color if-important($importantHack);
  }
}

@mixin button-solid-primary($importantHack: false) {
  background-color: $btn-solid-primary-bg if-important($importantHack);
  color: $btn-solid-primary-color if-important($importantHack);

  &:hover {
    background-color: $btn-solid-primary-hover-bg if-important($importantHack);
  }

  &.btn-disabled {
    color: $disabled-color if-important($importantHack);
    background-color: $disabled-bg if-important($importantHack);

    .oa:before {
      color: $disabled-color if-important($importantHack);
    }
  }

  .oa:before {
    color: $btn-solid-primary-color if-important($importantHack);
  }
}

@mixin button-outline-secondary($importantHack: false) {
  border: 2px solid $btn-outline-secondary-color if-important($importantHack);
  background-color: transparent if-important($importantHack);
  color: $btn-outline-secondary-color if-important($importantHack);

  &:hover {
    color: $btn-outline-secondary-hover-color if-important($importantHack);
    border-color: $btn-outline-secondary-hover-color
      if-important($importantHack);

    .oa:before {
      color: $btn-outline-secondary-hover-color if-important($importantHack);
    }
  }

  &.btn-disabled {
    color: $disabled-outline-color if-important($importantHack);
    background-color: $disabled-outline-bg if-important($importantHack);
    border-color: $disabled-outline-border-color if-important($importantHack);

    .oa:before {
      color: $disabled-outline-color if-important($importantHack);
    }
  }

  .oa:before {
    color: $btn-outline-secondary-color if-important($importantHack);
  }
}

@mixin button-outline-primary($importantHack: false) {
  border: 2px solid $btn-outline-primary-color if-important($importantHack);
  background-color: transparent if-important($importantHack);
  color: $btn-outline-primary-color if-important($importantHack);

  &:hover {
    color: $btn-outline-primary-hover-color if-important($importantHack);
    border-color: $btn-outline-primary-hover-color if-important($importantHack);

    .oa:before {
      color: $btn-outline-primary-hover-color if-important($importantHack);
    }
  }

  &.btn-disabled {
    color: $disabled-outline-color if-important($importantHack);
    background-color: $disabled-outline-bg if-important($importantHack);
    border-color: $disabled-outline-border-color if-important($importantHack);

    .oa:before {
      color: $disabled-outline-color if-important($importantHack);
    }
  }

  .oa:before {
    color: $btn-outline-primary-color if-important($importantHack);
  }
}

.btn-onair {
  @include button-base();

  &.btn-disabled:hover {
    cursor: not-allowed;
  }

  &.btn-grow {
    width: 100%;
  }

  .oa:before {
    transition: color 200ms ease-in-out;
  }

  &.btn-loading {
    cursor: not-allowed;
  }

  &.btn-solid.btn-secondary {
    @include button-solid-secondary;
  }

  &.btn-solid.btn-primary {
    @include button-solid-primary;
  }

  &.btn-outline.btn-secondary {
    @include button-outline-secondary;
  }

  &.btn-outline.btn-primary {
    @include button-outline-primary;
  }
}

.btn-link {
  @include link-primary();

  &:focus {
    color: $primary-midtone;
  }
}

@keyframes loading-spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
