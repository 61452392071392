$dm-sans-weight-regular: 400;
$dm-sans-weight-medium: 500;
$dm-sans-weight-bold: 700;
$poppins-weight-light: 300;
$poppins-weight-bold: 700;

$body-text-line-height: 24px;

$featured-title: 72px;
$featured-title-line-height: 88px;
$featured-title-mobile: 48px;
$featured-title-mobile-line-height: 56px;

$title: 40px;
$title-line-height: 48px;
$title-mobile: 32px;
$title-mobile-line-height: 40px;

$subtitle: 32px;
$subtitle-line-height: 40px;
$subtitle-mobile: 24px;
$subtitle-mobile-line-height: 24px;

$section-title: 26px;
$section-title-line-height: 32px;
$section-title-mobile: 22px;
$section-title-mobile-line-height: 24px;

$caption-text: 13px;
$caption-text-line-height: 16px;
$caption-text-mobile: 13px;
$caption-text-mobile-line-height: 16px;

@mixin featured-title {
  font-family: var(--font-poppins);
  font-weight: $poppins-weight-light;
  color: $text-primary;

  font-size: $featured-title-mobile;
  line-height: $featured-title-mobile-line-height;
  letter-spacing: 0.01em;

  padding: 0;
  margin: 0;

  .oa {
    &:before {
      color: $text-primary;
    }
  }
}

@mixin featured-title-lg {
  font-size: $featured-title;
  line-height: $featured-title-line-height;
}

@mixin title {
  font-family: var(--font-poppins);
  font-weight: $poppins-weight-light;
  color: $text-primary;

  font-size: $title-mobile;
  line-height: $title-mobile-line-height;

  padding: 0;
  margin: 0;

  .oa {
    &:before {
      color: $text-primary;
    }
  }
}

@mixin title-lg {
  font-size: $title;
  line-height: $title-line-height;
}

.text-shadow-light {
  text-shadow: 0px 1px 2px rgba(18, 17, 18, 0.75);
}

@mixin subtitle {
  font-family: var(--font-poppins);
  font-weight: $poppins-weight-light;
  color: $text-primary;

  font-size: $subtitle-mobile;
  line-height: $subtitle-mobile-line-height;

  padding: 0;
  margin: 0;

  .oa {
    &:before {
      color: $text-primary;
    }
  }
}

@mixin subtitle-lg {
  font-size: $subtitle;
  line-height: $subtitle-line-height;
}

@mixin section-title {
  font-family: var(--font-poppins);
  font-weight: $poppins-weight-light;
  color: $text-primary;

  font-size: $section-title-mobile;
  line-height: $section-title-mobile-line-height;

  padding: 0;
  margin: 0;

  .oa {
    &:before {
      color: $text-primary;
    }
  }
}

@mixin section-title-lg {
  font-size: $section-title;
  line-height: $section-title-line-height;
}

@mixin caption-text {
  font-family: var(--font-dm-sans);
  font-weight: $dm-sans-weight-regular;
  color: $text-primary;

  font-size: $caption-text-mobile;
  line-height: $caption-text-mobile-line-height;

  padding: 0;
  margin: 0;

  .oa {
    &:before {
      color: $text-primary;
    }
  }
}

@mixin caption-text-lg {
  font-size: $caption-text;
  line-height: $caption-text-line-height;
}

@mixin body-title {
  font-family: var(--font-dm-sans);
  font-weight: $dm-sans-weight-bold;
  color: $text-primary;

  font-size: 16px;
  line-height: 24px;

  padding: 0;
  margin: 0;

  .oa {
    &:before {
      position: relative;
      font-size: 24px;
      color: $text-primary;
    }
  }
}

@mixin body-text {
  font-family: var(--font-dm-sans);
  font-weight: $dm-sans-weight-regular;
  color: $text-primary;

  font-size: 16px;
  line-height: $body-text-line-height;

  padding: 0;
  margin: 0;

  .oa {
    &:before {
      position: relative;
      font-size: 24px;
      color: $text-primary;
    }
  }
}

@mixin body-link {
  font-family: var(--font-dm-sans);
  font-weight: $dm-sans-weight-regular;
  color: $text-primary;

  font-size: 16px;
  line-height: 24px;

  padding: 0;
  margin: 0;

  .oa {
    &:before {
      color: $text-primary;
    }
  }
}

@mixin caption-title {
  font-family: var(--font-dm-sans);
  font-weight: $dm-sans-weight-bold;
  color: $text-primary;

  font-size: 13px;
  line-height: 16px;

  padding: 0;
  margin: 0;

  .oa {
    &:before {
      color: $text-primary;
    }
  }
}

// NOTES:
// - when changes are made to the responsivess, also apply them in otnotice.scss - markup of external can not be changed so we need to copy over there
// - when changes are made to the responsiveness, also apply them in cookie-policys.scss
