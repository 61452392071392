/*
  .oooooo.   ooooo      ooo            .o.       ooooo ooooooooo.
 d8P'  `Y8b  `888b.     `8'           .888.      `888' `888   `Y88.
888      888  8 `88b.    8           .8"888.      888   888   .d88'
888      888  8   `88b.  8          .8' `888.     888   888ooo88P'
888      888  8     `88b.8         .88ooo8888.    888   888`88b.
`88b    d88'  8       `888        .8'     `888.   888   888  `88b.
 `Y8bood8P'  o8o        `8       o88o     o8888o o888o o888o  o888o

THEME - import all files needed for the On Air styling here */

@import 'globals.css';

// MIXINS
@import 'mixins/_focus';
@import 'mixins/link';

// VARIABLE/THEME IMPORTS
@import '_variables';
@import '_root';
@import '_iconography';
@import '_custom-normalize';

// VENDOR FILES
@import 'vendor/normalize/normalize';
@import 'vendor/bootstrap-5/bootstrap-grid';
@import 'vendor/onair-icons/style';

// Imported typography later to make sure that "media-breakpoint-up" mixin was defined
@import '_typography';

// HELPER IMPORTS
@import 'helpers/typography';
@import 'helpers/pull';
@import 'helpers/sizing';
@import 'helpers/container';
@import 'helpers/visibility';
@import 'helpers/flex-helpers';
@import 'helpers/border';
@import 'helpers/color';
@import 'helpers/interactions';
@import 'helpers/unstyled';
@import 'helpers/important';

// COMPONENT IMPORTS
// Storybook related
@import 'components/storybook/colorbadge';
@import 'components/storybook/iconbadge';

// General related
@import 'components/link';
@import 'components/onair-input';
@import 'components/onair-button'; // Should overwrite link, do not change order
@import 'components/onair-checkbox.scss';
@import 'components/onair-dropdown';
@import 'components/content-block';
@import 'components/highlights-block';
@import 'components/footer';
@import 'components/socials';
// keep the import before navbar, because page-banner defines $page-banner-height which it's used in the navbar
@import 'components/page-banner';
@import 'components/shell';
@import 'components/navbar';
@import 'components/navbar-mobile';
@import 'components/newsletter-signup';
@import 'components/login-register';
@import 'components/onair-date-input';
@import 'components/carousel';
@import 'components/connect';
@import 'components/testimonial-carousel-item';
@import 'components/event-carousel-item';
@import 'components/onair-notification';
@import 'components/side-navigation';
@import 'components/my-place';
@import 'components/hero/hero';
@import 'components/hero/hero-event-configurator';
@import 'components/hero/hero-event-info';
@import 'components/event-selling-point';
@import 'components/youtube-player';
@import 'components/gallery';
@import 'components/ticket';
@import 'components/card';
@import 'components/my-tickets-empty-state';
@import 'components/upcoming-event-countdown';
@import 'components/loading-spinner';
@import 'components/past-event';
@import 'components/live-event-sale-closed';
@import 'components/event-country-availability.scss';
@import 'components/cart';
@import 'components/contact';
@import 'components/file-uploader';
@import 'components/otnotice';
@import 'components/error';
@import 'components/skip-main';
@import 'components/cookie-policy';
@import 'components/app-store-banner';
@import 'components/add-to-calendar';
@import 'components/embedded-spotify-player';
@import 'components/freshdesk.scss';
@import 'components/search.scss';
@import 'components/accordion';
@import 'components/popover';
@import 'components/video-player-wrapper';
@import 'components/event-grid';
@import '_generic';
