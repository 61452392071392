.onair-dropdown {
  position: relative;
  display: inline-flex;
  flex-direction: column;

  $wrapper-background-color: $input-bg;

  &.onair-dropdown--open {
    .onair-dropdown__toggle {
      border-radius: 8px 8px 0 0;

      &:after {
        transform: rotate(180deg);
      }
    }
  }

  .onair-dropdown__label::first-letter {
    text-transform: uppercase;
  }

  .onair-dropdown__wrapper {
    position: relative;
  }

  .onair-dropdown__label {
    margin: 8px 0;
  }

  .onair-dropdown__toggle {
    position: relative;
    display: flex;
    justify-content: space-between;
    width: 100%;
    min-width: auto;
    padding: 14px 12px;
    margin: 0;
    color: $input-color;
    background-color: $input-bg;
    border: none;
    border-radius: 8px;
    box-shadow: inset 0 0 0 2px $shade-3;
    transition: border-radius 200ms ease-in-out;
    cursor: pointer;
    text-align: start;
    z-index: 2;

    &.onair-dropdown--error {
      box-shadow: inset 0 0 2px $error-midtone;
    }

    &:after {
      font-family: $icomoon-font-family;
      content: $oa-chevron-down;
      color: $input-color;

      font-size: 24px;

      transition: transform 200ms ease-in-out;
    }

    &:disabled {
      cursor: not-allowed;
      background-color: $disabled-bg;
      box-shadow: inset 0 0 0 2px $disabled-border-color;
    }
  }

  .onair-dropdown__menu {
    position: absolute;
    display: flex;
    flex-direction: column;
    padding: 0;
    margin: 0;
    width: 100%;
    background-color: $wrapper-background-color;
    border-radius: 0 0 8px 8px;
    transition-duration: 200ms;
    transition-property: opacity, top;
    visibility: hidden;
    overflow-x: hidden;
    max-height: 360px;
    pointer-events: none;
    z-index: 100;
    min-width: 50px;

    &-enter {
      visibility: visible;
      opacity: 0;
      top: 24px;
    }

    &-enter-active {
      visibility: visible;
      opacity: 1;
      top: 48px;
    }

    &-enter-done {
      visibility: visible;
      opacity: 1;
      top: 48px;
      pointer-events: all;
    }

    &-exit {
      visibility: visible;
      opacity: 1;
      top: 48px;
    }

    &-exit-active {
      visibility: visible;
      opacity: 0;
      top: 24px;
    }

    &-exit-done {
      visibility: hidden;
      opacity: 0;
      top: 24px;
    }

    .onair-dropdown__option {
      --dropdown-option-border-width: 2px;

      position: relative;
      padding: 10px;
      color: $input-color;
      background-color: $input-bg;
      cursor: pointer;
      text-align: start;
      z-index: 1;
      border: var(--dropdown-option-border-width) solid transparent;
      border-radius: 8px;

      &[aria-selected='true'] {
        &:before {
          position: absolute;
          top: calc(
            var(--dropdown-option-border-width) * -1
          ); // Negative so the element covers the option's border
          left: calc(
            var(--dropdown-option-border-width) * -1
          ); // Negative so the element covers the option's border
          display: block;
          content: '';
          width: 4px;
          height: calc(
            100% + var(--dropdown-option-border-width) * 2
          ); // double the border to be over the container's border
          background-color: $primary-midtone;
        }
      }

      &:focus-visible {
        outline: none;
        border-color: $primary-midtone;
      }

      @supports not selector(:focus-visible) {
        &:focus {
          outline: none;
          border-color: $primary-midtone;
        }
      }

      &:hover {
        border-radius: 0px;
        background-color: $accent-darkest;
        cursor: pointer;
      }
    }
  }

  &.onair-dropdown--dark {
    $wrapper-background-color: $shade-7;

    .onair-dropdown__menu,
    .onair-dropdown__menu-enter-done {
      transform: translateX(-8px);
      $wrapper-background-color: $shade-7;
      padding: 0px 8px;
      color: rgba($text-primary, 0.75);
      background-color: $wrapper-background-color;
      box-shadow: 0 0 0 1px $shade-3;
      min-width: 120px;
    }

    .onair-dropdown__option {
      color: rgba($text-primary, 0.75);
      background-color: $wrapper-background-color;
      &:hover {
        background-color: $shade-6;
      }
      &[aria-selected='true'] {
        &:before {
          width: 2px;
        }
      }
    }
  }
}
