.testimonial-content {
  background: $shade-7;
  height: 100%;
  padding: 16px;
  display: flex;
  flex-direction: row;

  .testimonial-content--left {
    display: flex;
    justify-content: center;
    margin-right: 16px;
  }
  &__header {
    display: flex;
    justify-content: space-between;
    .icon {
      font-size: 24px;
    }
  }

  &__image {
    height: 48px;
    width: 48px;
    border-radius: 50%;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 16px;
    border-radius: 50% !important;
    background-color: $primary-midtone;
  }
  .author-name {
    white-space: normal;
  }
  .job-title {
    color: $shade-3;
    white-space: normal;
  }
  &__rating {
    display: flex;
    align-items: center;
    .stars {
      margin-right: 8px;
    }
    .created-time {
      font-family: var(--font-dm-sans);
      color: $shade-3;
      font-size: 13px;
      line-height: 16px;
    }
  }

  p {
    white-space: normal;
  }

  a {
    @include link-primary();
    font-weight: $dm-sans-weight-regular;
    font-family: var(--font-dm-sans);
  }
}
