.connect-page {
  margin: 96px auto;

  &__error {
    color: $primary-midtone;
  }

  .warning-icon {
    margin-right: $spacer-1-value;
    &::before {
      font-size: 18px;
      color: $primary-midtone;
    }
  }

  .connect-inputs {
    max-width: 515px;
    .input {
      &:not(:last-child) {
        margin-right: $spacer-1-value;
      }

      input {
        font-size: 24px;
        font-weight: $font-weight-bold;
        text-align: center;
        text-transform: uppercase;
        width: 50px;
        height: 80px;
        padding: $spacer-1-value;
      }
    }
  }
}

@include media-breakpoint-up(sm) {
  .connect-page {
    .connect-inputs {
      .input {
        &:not(:last-child) {
          margin-right: $spacer-2-value;
        }

        input {
          width: 72px;
          height: 96px;
        }
      }
    }
  }
}
