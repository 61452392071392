.hero-wrapper {
  min-height: 524px;
  height: 100%;
  position: relative;
  text-align: center;
  margin-top: calc(var(--navbar-height) * -1);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

  &--event {
    // min-height to have the logo + 3 levels of options
    min-height: 560px;
    height: 65vh;
  }

  &.hero-wrapper--theme-default {
    i:before,
    .body-text {
      color: $shade-2;
    }

    .event-info__from {
      color: $primary-midtone;
    }
  }

  &.hero-wrapper--theme-dark {
    i:before,
    .body-text {
      color: $shade-6;
    }

    .event-info__details .event-info__from {
      color: $secondary-midtone;
    }

    .hero-wrapper__gradient {
      background: transparent;
    }
  }

  &.hero-wrapper--center-content {
    .hero__logo img,
    .hero__event-image img {
      width: 100%;
      margin: 0 auto;
    }
  }

  .hero-wrapper__video {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .hero-wrapper__gradient {
    position: absolute;
    padding-top: 50px;
    width: 100%;
    height: 100%;
    // z-index: 1;

    background: linear-gradient(
      180deg,
      rgba(25, 24, 26, 0.0001) 0%,
      #19181a 100%
    );
    background-position: center;
  }

  .hero__icon:before {
    font-size: 24px;
  }

  .live-event-sale-closed-wrapper {
    .hero__logo,
    .hero__event-image {
      object-position: center !important;
      position: relative !important;
    }
  }

  .hero__content {
    position: relative;
    display: flex;
    height: 100%;
    margin-top: auto;
    padding-bottom: 50px;
    flex-direction: column;
    justify-content: flex-end;
    gap: 1rem;

    &--center {
      align-items: center;
    }
    &--left {
      align-items: flex-start;
    }
    &--right {
      align-items: flex-end;
    }
    &__logo {
      max-height: 80px;
      width: auto;
    }

    .hero__logo,
    .hero__event-image {
      width: 100%;
      max-height: 90px;
      align-self: center;
      object-position: center !important;
    }

    .hero__generic-button {
      flex: 1;
    }

    .hero__meta-content {
      display: flex;
      flex-direction: center;
      align-items: center;
      color: $shade-3;

      img {
        border-radius: 50%;
        margin-right: 8px;
        object-fit: cover;
      }
      span {
        margin: 0.5rem;
      }
    }
  }
}

@include media-breakpoint-up(sm) {
  .hero-wrapper {
    min-height: 560px;
    height: auto;

    &--event {
      min-height: 712px;
      height: auto;
    }

    .hero__content {
      padding-bottom: 92px;
    }
  }
}

@include media-breakpoint-up(md) {
  .hero-wrapper {
    min-height: 712px;
    height: auto;
    text-align: left;

    &--event {
      min-height: 712px;
      height: auto;
    }

    &.background-image--left {
      .hero-wrapper__gradient {
        background: linear-gradient(
          270deg,
          rgba(25, 24, 26, 0) 0.83%,
          #19181a 80%
        );
      }
    }
    &.background-image--center {
      .hero-wrapper__gradient {
        background: radial-gradient(
          46.75% 63.43% at 50% 50%,
          #19181a 0%,
          rgba(24, 25, 26, 0) 100%
        );
      }
    }
    &.background-image--right {
      .hero-wrapper__gradient {
        background: linear-gradient(
          90deg,
          rgba(25, 24, 26, 0) 0%,
          #19181a 100%
        );
      }
    }

    .hero-wrapper__bottom-gradient {
      position: absolute;
      height: 72px;
      left: 0px;
      right: 0px;
      bottom: 0px;

      background: linear-gradient(
        180deg,
        rgba(25, 24, 26, 0.0001) 0%,
        #19181a 100%
      );
    }

    .hero__content {
      padding-top: 8px;
      padding-bottom: 304px;

      &__logo {
        max-height: 160px;
        width: auto;
      }

      .hero__logo,
      .hero__event-image {
        max-width: 336px;
        max-height: 120px;
        align-self: flex-start;
        object-position: left center !important;

        img {
          width: auto;
        }
      }

      &.hero__error {
        margin-top: 200px;
      }

      .hero__generic-button {
        flex: 0;
        text-align: center;
        min-width: 230px;
      }
    }
  }
}

@include media-breakpoint-up(lg) {
  .hero-wrapper {
    min-height: 880px;

    &--event {
      min-height: 880px;
    }

    .hero-wrapper__bottom-gradient {
      height: 80px;
    }

    .hero__content {
      padding-bottom: 400px;

      .hero__logo,
      .hero__event-image {
        max-width: none;
        max-height: 120px;
      }

      .hero__generic-button {
        min-width: 275px;
      }
    }
  }
}
@include media-breakpoint-up(xxl) {
  .hero-wrapper {
    height: 880px;

    &--event {
      height: 880px;
    }

    .hero-wrapper__bottom-gradient {
      height: 104px;
    }

    .hero__content {
      padding-bottom: 240px;
      .hero__logo,
      .hero__event-image {
        max-height: 128px;
      }
    }
  }
}
