.contact-page__wrapper {
  .contact-socials .socials .oa {
    &:before {
      font-size: 32px;
      padding: 4px;
    }
  }
  .socials__link-wrapper {
    a:first-child {
      margin-left: 0;
    }
  }

  .category-login-warning {
    background: none;
    border: none;
    cursor: pointer;
    color: $error-midtone;
    padding: 0;
    font-weight: bold;

    &:hover,
    &:focus {
      color: $error-dark;
    }
  }

  .onair-dropdown {
    width: 100%;
  }
  .contact-onair-location {
    width: 100%;
    max-height: 243px;
  }
  .contact-page__content {
    display: flex;
    flex-direction: column;
    .item {
      flex-basis: 100%;
    }
  }
  .hero-wrapper .hero__content {
    justify-content: flex-end;
  }
}

@include media-breakpoint-up(sm) {
  .contact-page__wrapper {
    .contact-page__content {
      flex-direction: row;
    }
  }
}

@include media-breakpoint-up(md) {
  .contact-page__wrapper {
    .contact-onair-location {
      height: 400px;
    }
  }
}

@include media-breakpoint-up(lg) {
  .contact-page__wrapper {
    .name-and-surname {
      display: flex;
      justify-content: space-between;
      > div {
        flex-basis: 100%;
        &:first-child {
          margin-right: 16px;
        }
      }
    }
  }
}
