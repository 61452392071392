.popover {
  background-color: $bg-popover;
  padding-block: 12px;
  padding-inline: $spacer-2-value;
  border-radius: 8px;

  svg {
    fill: $bg-popover;
  }

  &__title {
    font-weight: 700;
    margin-bottom: 12px;
  }

  &__buttons {
    display: flex;
    margin-top: 12px;
    justify-content: end;
    gap: $spacer-2-value;

    button {
      height: 40px;
    }
  }
}

.popover-trigger {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 9999px;

  &:has(.oa) {
    height: 24px;
    width: 24px;
  }
}
