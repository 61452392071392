.colorbadge {
  display: flex;
  flex-direction: row;
  border: 1px solid $border-color;
  align-items: center;

  // Border radius and overflow so that the badge doesnt come out
  border-radius: 4px;
  overflow: hidden;

  // Some spacing
  margin-bottom: 10px;
  background-color: #fff;

  p {
    color: $text-secondary;
  }

  .colorbadge__badge {
    margin-right: 20px;

    width: 50px;
    height: 50px;
  }
}
