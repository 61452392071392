.navbar .navbar__mobile-transparent {
  width: 100%;
  height: 100%;
  position: fixed;
  right: 0;
  top: 0;

  display: none;

  &.navbar__mobile--visible {
    display: block;
  }
}

.navbar .navbar__mobile {
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  z-index: 100;
  background-color: $bg-dark;
  width: 80%;
  height: 100%;
  overflow: auto;

  &.navbar__mobile--left {
    transition: left 0.4s ease-in-out;
    left: -80%;

    &.navbar__mobile--visible {
      left: 0;
    }
  }

  &.navbar__mobile--right {
    width: 100%;
    transition: right 0.4s ease-in-out;
    right: -100%;

    &.navbar__mobile--visible {
      right: 0;
    }
  }

  .navbar-mobile__head {
    font-size: 24px;
    padding: 12px;
    display: flex;
    flex-direction: row;

    i {
      &:hover {
        cursor: pointer;
      }

      &:before {
        color: $shade-2;
      }
    }

    &.navbar-mobile__head--border {
      border-bottom: 1px solid $shade-3;
      padding-top: 8px;
    }

    .head__profile-name {
      padding: 12px 24px 8px 24px;
      flex-grow: 1;
    }

    .head__close {
      border-radius: 20px 20px 20px 20px;
      padding: 0;
      padding-top: 4px;
      margin-top: 8px;
      margin-left: 8px;
      line-height: 1;

      &:hover {
        cursor: pointer;
      }

      i {
        font-size: 24px;
      }
    }

    .head__expand {
      z-index: 102;
    }

    .head__search {
      z-index: 101;
    }

    .head__input-wrapper {
      position: absolute;
      right: 16px;
      margin-left: 56px;

      display: flex;
      flex-direction: row;
      justify-content: flex-end;

      input {
        padding: 8px 48px 8px 24px;
        opacity: 0;
        max-width: 10%;
        transition:
          opacity 0.2s ease-in-out,
          max-width 0.2s ease-in-out;
        pointer-events: none;
      }

      i.oa-search {
        position: absolute;

        &:before {
          position: absolute;
          top: 2px;
          right: 8px;
          padding: 8px;
        }
      }

      &.head__input-wrapper--expanded {
        input {
          opacity: 1;
          max-width: 100%;
          pointer-events: auto;
        }

        .head__expand {
          display: none;
        }

        .head__search:before {
          color: $primary-midtone;
        }
      }
    }
  }

  .navbar-mobile__body {
    padding-top: 24px;

    a {
      position: relative; // Make child listen to the size
      display: block;
      font-family: var(--font-poppins);
      font-weight: $poppins-weight-light;
      text-decoration: none;
      font-size: 18px;
      line-height: 24px;
      color: $shade-2;
      padding: 8px 8px 8px 22px;

      i {
        padding-right: 8px;
        font-size: 24px;

        &:before {
          color: $shade-2;
        }
      }

      &:before {
        max-width: 0px;
        width: 2px;
        height: 100%;
        background-color: $shade-2;
        display: block;
        content: '';
        position: absolute;

        left: 0;
        top: 0;

        transition:
          max-width 0.1s ease-in-out,
          background-color 0.1s ease-in-out;
      }

      &.link--active {
        font-weight: $poppins-weight-bold;

        &:before {
          background-color: $primary-midtone;
          max-width: 2px;
        }
      }

      &:hover:before {
        max-width: 2px;
        background-color: $shade-2;
      }
    }
  }

  // Styling related to the SideNavigation
  .navbar-mobile__my-place {
    button,
    a {
      margin: 0;
      padding: 8px 8px 8px 22px;

      i {
        padding-left: 22px;
      }
    }
  }

  .navbar-mobile__footer {
    margin-top: auto;
    width: 100%;
    text-align: center;
    padding: 24px 0;

    svg {
      max-height: 32px;
    }
  }
}

@include media-breakpoint-up(lg) {
  .navbar .navbar__mobile {
    display: none;
  }
}
