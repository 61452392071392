.shell-wrapper {
  padding-top: var(--navbar-height);

  &:has(.platform-message-banner),
  &:has(.countdown-banner) {
    .navbar,
    #root {
      top: $page-banner-height;
    }
    #root {
      position: relative;
    }
  }
}
