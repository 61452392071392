// Used to style OneTrust content with that gets loaded dynamically with JS

.otnotice {
  padding-top: 40px;

  .otnotice-sections .otnotice-section:first-child {
    display: none;
  }

  .otnotice-content {
    padding: 0;
  }

  .otnotice-section-header {
    @include body-title;
  }

  .otnotice-section-content {
    @include body-text;
  }

  a {
    @include body-link;
    @include link;

    @include link-primary();

    .oa:before {
      @include link-primary();
    }
  }

  .otnotice-timestamp {
    @include body-title;
  }
}
