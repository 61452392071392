.unstyled-button {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  cursor: pointer;
  outline: inherit;
}

.unstyled-list {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
