@mixin link {
  border-radius: 20px;
  text-decoration: none;
}

@mixin link-primary {
  @include link();
  color: $primary-midtone;

  &:hover,
  &:focus {
    color: $primary-dark;

    .oa:before {
      color: $primary-dark;
    }
  }

  &:active {
    color: $primary-light;

    .oa:before {
      color: $primary-light;
    }
  }
}

@mixin link-secondary {
  @include link();
  color: $secondary-midtone;

  &:hover,
  &:focus {
    color: $secondary-dark;

    .oa:before {
      color: $secondary-dark;
    }
  }

  &:active {
    color: $secondary-light;

    .oa:before {
      color: $secondary-light;
    }
  }
}

@mixin link-tertiary {
  @include link();
  color: $shade-2;

  &:hover,
  &:focus {
    color: $shade-3;

    .oa:before {
      color: $shade-3;
    }
  }

  &:active {
    color: $shade-4;

    .oa:before {
      color: $shade-4;
    }
  }
}
