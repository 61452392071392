$carousel-margin-right: 8px;
$carousel-md-margin-right: 16px;
$carousel-lg-margin-right: 24px;

$carousel-border-radius: 10px;
$carousel-lg-border-radius: 20px;

// Carousel title sizing
$carousel-width: 256px;
$carousel-height: 390px;
$carousel-landscape-width: 256px;
$carousel-landscape-height: 160px;

$carousel-sm-width: 200px;
$carousel-sm-height: 304px;
$carousel-landscape-sm-width: 256px;
$carousel-landscape-sm-height: 160px;

$carousel-md-width: 160px;
$carousel-md-height: 240px;
$carousel-landscape-md-width: 265px;
$carousel-landscape-md-height: 160px;

$carousel-lg-width: 296px;
$carousel-lg-height: 496px;
$carousel-landscape-lg-width: 296px;
$carousel-landscape-lg-height: 176px;

$carousel-xxl-width: 392px;
$carousel-xxl-height: 588px;
$carousel-landscape-xxl-width: 392px;
$carousel-landscape-xxl-height: 232px;

.carousel {
  position: relative;
  z-index: 1;
  padding: 0;

  .carousel__wrapper {
    position: relative;

    .carousel__items {
      position: relative; // Make shadow overlay listen
      width: 100%;

      // Trick to make sure that the carousel event focus overlay is displayed correctly
      padding: 10px 0;
      margin: -10px 0;

      flex: 1;
      flex-wrap: nowrap;
      display: flex;
      overflow: auto;

      white-space: nowrap;
      scroll-behavior: smooth;

      scroll-snap-type: x mandatory;

      scroll-padding-left: var(--bs-gutter-x, 0.75rem);
      scroll-padding-right: var(--bs-gutter-x, 0.75rem);

      &::-webkit-scrollbar {
        display: none;
      }

      .carousel__item {
        box-sizing: border-box;
        scroll-snap-align: start;
        transition:
          transform 0.3s ease,
          border-color 0.2s ease;

        flex: 0 0 $carousel-width;
        height: $carousel-height;

        margin-right: $carousel-margin-right;
        position: relative; // Needed to keep the shadow inside

        border-radius: $carousel-border-radius;
        overflow: hidden;
        // Trick for border-radius to work on macOs devices
        perspective: 1px;
        border: 2px solid transparent;

        a {
          display: block;
          height: 100%;
          width: 100%;
        }

        &.auto-height {
          height: auto !important;
        }

        &.no-radius {
          border-radius: 0;
        }

        &:not(.carousel__item-pusher):not(.no-radius):hover {
          cursor: pointer;
          border-color: $shade-1;
          // transform: scale(1.03); // TODO potentially fix this feature sometime in the future. For now it's disabled as it causes laggy behaviour
          box-shadow: 0 0 10px $shade-7;
        }

        &.carousel__item--last {
          margin-right: 0;
        }

        &.carousel__item-pusher,
        &.carousel__item-pusher--landscape {
          border: 0;
          margin-right: 0;

          // Setting on mobile the width of the initial pusher to '12px' to avoid alignment issues.
          // The pusher at the end needs some width to ensure that the user can slide all the way to the end
          &:not(.carousel__item-pusher--end) {
            flex: 0 0 12px;
          }
        }

        .carousel__shadow-overlay {
          width: 100%;
          height: 100%;
          position: absolute;
          background: linear-gradient(
            180deg,
            rgba(18, 17, 18, 0) 0%,
            rgba(18, 17, 18, 0.7) 100%,
            #121112 100%
          );
          pointer-events: none;
        }
      }
    }

    .carousel__shadow-overlay {
      pointer-events: none;
      position: absolute;
      width: 0;
      height: 100%;

      top: 0;
      z-index: 50;

      &.carousel__shadow-overlay--start {
        left: 0;
        background-image: linear-gradient(
          to right,
          rgba($shade-6, 100),
          rgba($shade-6, 0)
        );
      }

      &.carousel__shadow-overlay--end {
        right: 0;
        background-image: linear-gradient(
          to left,
          rgba($shade-6, 100),
          rgba($shade-6, 0)
        );
      }
    }

    .carousel__button {
      background-color: $shade-2;
      top: calc(50% - 24px);
      width: 48px;
      height: 48px;
      border-radius: 50%;

      text-align: center;
      position: absolute;
      z-index: 51;

      font-size: 24px;
      padding-top: 9px;

      transition:
        0.2s background-color ease,
        opacity 0.2s ease;

      &:before {
        font-family: $icomoon-font-family;
        color: $shade-6;
      }

      &:hover {
        cursor: pointer;
        background-color: $shade-3;
      }

      &--left {
        left: 10px;
        padding-right: 2px;

        &:before {
          content: $oa-chevron-left;
        }
      }

      &--right {
        right: 10px;

        &:before {
          padding-left: 3px;
          content: $oa-chevron-right;
        }
      }
    }
  }
}

.carousel .carousel__wrapper .carousel__items {
  .carousel__item {
    &.carousel__item--small {
      height: 390px;
      flex: 0 0 256px;
    }
  }
}
.carousel--landscape .carousel__wrapper .carousel__items {
  .carousel__item {
    height: $carousel-landscape-height;
    flex: 0 0 $carousel-landscape-width;
    &.carousel__item--small {
      height: 160px;
      flex: 0 0 256px;
    }
  }
}

@include media-breakpoint-up(sm) {
  .carousel .carousel__wrapper .carousel__items {
    .carousel__item {
      height: $carousel-sm-height;
      margin-right: $carousel-margin-right;
      flex: 0 0 $carousel-sm-width;
      &.carousel__item--small {
        height: 276px;
        flex: 0 0 184px;
      }
    }
    margin-bottom: 4px;
  }

  .carousel.carousel--landscape .carousel__wrapper .carousel__items {
    .carousel__item {
      height: $carousel-landscape-sm-height;
      flex: 0 0 $carousel-landscape-sm-width;
    }
  }
  .carousel__items--small {
    overflow-y: hidden !important;
  }

  .carousel__items--small {
    height: 292px;
  }

  .carousel--landscape {
    .carousel__items--small {
      height: 192px;
    }
  }
}

@include media-breakpoint-up(md) {
  .carousel .carousel__items {
    .carousel__item {
      height: $carousel-md-height;
      margin-right: $carousel-md-margin-right;
      flex: 0 0 $carousel-md-width;
      &.carousel__item--small {
        height: 276px;
        flex: 0 0 184px;
      }
    }
  }

  .carousel.carousel--landscape .carousel__items {
    .carousel__item {
      height: $carousel-landscape-md-height;
      flex: 0 0 $carousel-landscape-md-width;
    }
  }

  .carousel__items--small {
    height: 292px;
  }
}

@include media-breakpoint-up(lg) {
  .carousel .carousel__wrapper .carousel__items {
    .carousel__item {
      height: $carousel-lg-height;
      flex: 0 0 $carousel-lg-width;
      margin-right: $carousel-lg-margin-right;
      border-radius: $carousel-lg-border-radius;
      &.carousel__item--small {
        height: 276px;
        flex: 0 0 184px;
      }
    }
  }

  .carousel.carousel--landscape .carousel__wrapper .carousel__items {
    .carousel__item {
      height: $carousel-landscape-lg-height;
      flex: 0 0 $carousel-landscape-lg-width;
    }
  }

  .carousel__items--small {
    height: 292px;
  }
}

@include media-breakpoint-up(xxl) {
  .carousel .carousel__wrapper .carousel__items {
    .carousel__item {
      height: $carousel-xxl-height;
      flex: 0 0 $carousel-xxl-width;
      &.carousel__item--small {
        height: 276px;
        flex: 0 0 184px;
      }
    }
  }

  .carousel.carousel--landscape .carousel__wrapper .carousel__items {
    .carousel__item {
      height: $carousel-landscape-xxl-height;
      flex: 0 0 $carousel-landscape-xxl-width;
      &.carousel__item--small {
        height: 160px;
        flex: 0 0 256px;
      }
    }
  }

  .carousel__items--small {
    height: 292px;
  }
}

// Using rems because bootstrap is using also rem units for aligning the container from the event title
$breakpoint-name-to-tile-offset: (
  sm: 1.5rem,
  md: 0.55rem,
  lg: 0.55rem,
  xl: 0.55rem,
  xxl: 0.55rem,
  fullHd: 0.55rem,
);

// Now set the shadow overlays
@each $breakpoint-width-name, $breakpoint-width-value in $grid-breakpoints {
  $container-width-value: map-get(
    $container-max-widths,
    $breakpoint-width-name
  );

  $carousel-title-offset: map-get(
    $breakpoint-name-to-tile-offset,
    $breakpoint-width-name
  );

  @media (min-width: $breakpoint-width-value) {
    @if ($container-width-value and $carousel-title-offset) {
      .carousel .carousel__wrapper .carousel__shadow-overlay {
        flex: 0
          0
          calc(
            (100vw - #{$container-width-value} + #{$carousel-title-offset}) / 2
          );
      }

      .carousel {
        .carousel__wrapper {
          .carousel__items {
            scroll-padding-left: calc(
              (100vw - #{$container-width-value} + #{$carousel-title-offset}) /
                2
            );
            scroll-padding-right: calc(
              (100vw - #{$container-width-value} + #{$carousel-title-offset}) /
                2
            );
            .carousel__item {
              &.carousel__item-pusher,
              &.carousel__item-pusher--landscape {
                flex: 0
                  0
                  calc(
                    (
                        100vw -
                          #{$container-width-value} +
                          #{$carousel-title-offset}
                      ) /
                      2
                  );

                &:not(.carousel__item-pusher--end) {
                  flex: 0
                    0
                    calc(
                      (
                          100vw -
                            #{$container-width-value} +
                            #{$carousel-title-offset}
                        ) /
                        2
                    );
                }
              }
            }
          }
        }
      }
    }
  }
}
