.exit-preview {
  @include body-text;
  background-color: $primary-midtone;
  position: fixed;
  bottom: 20px;
  right: 20px;
  border-radius: 8px;
  padding: 8px 16px;
  cursor: pointer;
  // shadow
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.3);
  z-index: 1000;
}

@media screen and (min-width: 1520px) {
  .container {
    width: 100%;
    max-width: none;
    padding-left: 80px;
    padding-right: 80px;
  }
  .highlight {
    max-width: 640px;
  }
  .past-event-not-purchased-wrapper {
    margin: 0 auto;
  }

  .content-block-container {
    .title,
    .description,
    .content-block__call-to-action {
      max-width: 640px;
    }
    &.justify-content-center {
      .title,
      .description,
      .content-block__call-to-action {
        margin: 0 auto;
      }
    }
  }

  .carousel {
    .carousel__wrapper {
      .carousel__items {
        .carousel__item {
          &.carousel__item-pusher,
          &.carousel__item-pusher--landscape {
            flex: 0 0 calc((100vw - (100% - (#{$spacer-8-value} * 2))) / 2);

            &:not(.carousel__item-pusher--end) {
              flex: 0 0 calc((100vw - (100% - (#{$spacer-8-value} * 2))) / 2);
            }
          }
        }
      }
    }
  }
}
