.accordion {
  display: flex;
  flex-direction: column;
  gap: $spacer-1-value;

  &__item {
    background-color: $bg-dark;
    border-radius: 10px;

    button {
      border-radius: 10px;
    }

    @supports selector(:has(*)) {
      @supports not selector(:focus-visible) {
        &:has(button:focus) {
          @include global-focus-style;

          button:focus {
            outline: none;
          }
        }
      }

      @supports selector(:focus-visible) {
        &:has(button:focus-visible) {
          @include global-focus-style;

          button:focus-visible {
            outline: none;
          }
        }
      }
    }
  }

  &__header {
    padding: $spacer-2-value;
  }

  &__title {
    display: flex;
    align-items: center;
    line-height: 32px;

    i {
      margin-right: $spacer-2-value;
      font-size: 32px;
    }
  }

  &__content {
    @include body-text();

    padding: $spacer-2-value;
    border-top: 1px solid $border-dark;

    a {
      @include link-primary();
      font-weight: $dm-sans-weight-regular;
      font-family: var(--font-dm-sans);
    }
  }
}
