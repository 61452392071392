.newsletter-signup {
  background-color: $shade-7;

  p {
    color: $accent-lightest;
  }

  .onair-input__wrapper {
    margin-right: 0 !important; // Input has an me-3 on it, also !important
  }

  .socials {
    margin-top: $spacer-3-value;
  }

  .newsletter-button {
    padding: 12px $spacer-3-value !important;
  }

  .newsletter-success-icon {
    &:before {
      font-size: 20px;
      color: $shade-1;
    }
  }

  .newsletter-success-title {
    color: $shade-1;
    text-transform: uppercase;
  }
  .newsletter-success-text {
    @include caption-text;
    color: $text-primary;
  }

  .link__no-style {
    color: $accent-lightest;
  }
}

@include media-breakpoint-up(md) {
  .newsletter-success-text {
    text-align: left;
  }
}
