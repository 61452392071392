$icomoon-font-family: 'On-Air-Icons' !default;
$icomoon-font-path: 'fonts' !default;

$oa-no-play-large: unquote('"\\e9c0"');
$oa-harmonica: unquote('"\\e9c1"');
$oa-saxophone: unquote('"\\e9c2"');
$oa-rocket: unquote('"\\e9bf"');
$oa-play-large: unquote('"\\e9ae"');
$oa-music-notes: unquote('"\\e9a5"');
$oa-globe-large: unquote('"\\e9a7"');
$oa-cup-large: unquote('"\\e9a8"');
$oa-stage: unquote('"\\e9a9"');
$oa-audio-large: unquote('"\\e9aa"');
$oa-cinema-set-large: unquote('"\\e9ab"');
$oa-home-large: unquote('"\\e9ac"');
$oa-lightning-large: unquote('"\\e9ad"');
$oa-quote-large: unquote('"\\e9af"');
$oa-sliders-large: unquote('"\\e9b0"');
$oa-star-large: unquote('"\\e9b1"');
$oa-team-large: unquote('"\\e9b2"');
$oa-thought-large: unquote('"\\e9b3"');
$oa-ticket-large: unquote('"\\e9b4"');
$oa-tv-large: unquote('"\\e9b5"');
$oa-user-large: unquote('"\\e9b6"');
$oa-warning-large: unquote('"\\e9b7"');
$oa-electric-guitar: unquote('"\\e989"');
$oa-hiphop-cap: unquote('"\\e98a"');
$oa-browser: unquote('"\\e98b"');
$oa-tourbus: unquote('"\\e98c"');
$oa-video-camera: unquote('"\\e98d"');
$oa-smartphone: unquote('"\\e98e"');
$oa-banjo: unquote('"\\e98f"');
$oa-vinyl-player: unquote('"\\e990"');
$oa-emotion-faces: unquote('"\\e991"');
$oa-guitar: unquote('"\\e992"');
$oa-headphones: unquote('"\\e993"');
$oa-trumpet: unquote('"\\e994"');
$oa-microphone: unquote('"\\e995"');
$oa-speaker: unquote('"\\e996"');
$oa-vintage-microphone: unquote('"\\e997"');
$oa-violin: unquote('"\\e998"');
$oa-volume-bars: unquote('"\\e999"');
$oa-exit-fullscreen: unquote('"\\e9c3"');
$oa-pip-off: unquote('"\\e9cb"');
$oa-speed: unquote('"\\e9c4"');
$oa-caption: unquote('"\\e9c5"');
$oa-not-playable: unquote('"\\e9c6"');
$oa-pip: unquote('"\\e9c7"');
$oa-phone-small: unquote('"\\e9c8"');
$oa-pin: unquote('"\\e9ca"');
$oa-dolby-atmos-vertical: unquote('"\\e9bc"');
$oa-dolby-atmos-vision-vertical: unquote('"\\e9bd"');
$oa-dolby-vision-atmos-vertical: unquote('"\\e9bd"');
$oa-dolby-vision-vertical: unquote('"\\e9be"');
$oa-4k-vertical: unquote('"\\e940"');
$oa-8k-vertical: unquote('"\\e944"');
$oa-surround-vertical: unquote('"\\e948"');
$oa-ambilight-vertical: unquote('"\\e95c"');
$oa-hd-vertical: unquote('"\\e96f"');
$oa-hdr-vertical: unquote('"\\e970"');
$oa-sd-vertical: unquote('"\\e971"');
$oa-sd: unquote('"\\e978"');
$oa-stereo-vertical: unquote('"\\e979"');
$oa-stream: unquote('"\\e981"');
$oa-sound-stereo: unquote('"\\e981"');
$oa-accessibility: unquote('"\\e9b8"');
$oa-lightningbolt: unquote('"\\e9b9"');
$oa-handshake: unquote('"\\e9ba"');
$oa-trend-up: unquote('"\\e9bb"');
$oa-backspace: unquote('"\\e939"');
$oa-categories: unquote('"\\e93a"');
$oa-cloud: unquote('"\\e9a4"');
$oa-space: unquote('"\\e9a6"');
$oa-thread: unquote('"\\e99a"');
$oa-twitter: unquote('"\\e99b"');
$oa-printer: unquote('"\\e99c"');
$oa-firetv: unquote('"\\e99d"');
$oa-youbora: unquote('"\\e99e"');
$oa-camera: unquote('"\\e99f"');
$oa-tv: unquote('"\\e9a0"');
$oa-clock: unquote('"\\e9a1"');
$oa-columns: unquote('"\\e9a2"');
$oa-sort: unquote('"\\e9a3"');
$oa-ambilight: unquote('"\\e988"');
$oa-copy: unquote('"\\e985"');
$oa-payment-card: unquote('"\\e986"');
$oa-tag: unquote('"\\e987"');
$oa-cinema-set: unquote('"\\e984"');
$oa-cup: unquote('"\\e97f"');
$oa-calendar-add: unquote('"\\e980"');
$oa-dots: unquote('"\\e982"');
$oa-blocked: unquote('"\\e983"');
$oa-gift: unquote('"\\e97e"');
$oa-jumpforward: unquote('"\\e97c"');
$oa-jumpback: unquote('"\\e97d"');
$oa-60secforward: unquote('"\\e97a"');
$oa-60secback: unquote('"\\e97b"');
$oa-imdb: unquote('"\\e977"');
$oa-check-circle: unquote('"\\e976"');
$oa-spinner-simple-large: unquote('"\\e975"');
$oa-loudspeaker-loud: unquote('"\\e94e"');
$oa-exclamation-mark: unquote('"\\e963"');
$oa-upload: unquote('"\\e968"');
$oa-download: unquote('"\\e96a"');
$oa-quote: unquote('"\\e972"');
$oa-music-note-1: unquote('"\\e973"');
$oa-music-note-2: unquote('"\\e974"');
$oa-secback: unquote('"\\e96d"');
$oa-10secback: unquote('"\\e96d"');
$oa-secforward: unquote('"\\e96e"');
$oa-10secforward: unquote('"\\e96e"');
$oa-thought: unquote('"\\e96b"');
$oa-onetree3: unquote('"\\e96c"');
$oa-onetree: unquote('"\\e966"');
$oa-onetree2: unquote('"\\e967"');
$oa-trashcan: unquote('"\\e969"');
$oa-film: unquote('"\\e958"');
$oa-lighting: unquote('"\\e962"');
$oa-hearth: unquote('"\\e964"');
$oa-speech-bubble: unquote('"\\e965"');
$oa-retry: unquote('"\\e95d"');
$oa-star-filled: unquote('"\\e960"');
$oa-team: unquote('"\\e961"');
$oa-fastforward: unquote('"\\e949"');
$oa-rewind: unquote('"\\e95a"');
$oa-live: unquote('"\\e92e"');
$oa-loudspeaker-low: unquote('"\\e94f"');
$oa-volume-down: unquote('"\\e94f"');
$oa-loudspeaker-off: unquote('"\\e950"');
$oa-mute: unquote('"\\e950"');
$oa-loudspeaker: unquote('"\\e951"');
$oa-tiktok2: unquote('"\\e957"');
$oa-wifi-warning: unquote('"\\e959"');
$oa-eye-closed: unquote('"\\e94c"');
$oa-hide: unquote('"\\e94c"');
$oa-sliders: unquote('"\\e94d"');
$oa-frame: unquote('"\\e952"');
$oa-full-screen: unquote('"\\e952"');
$oa-chromecast: unquote('"\\e953"');
$oa-chromecast-casting: unquote('"\\e954"');
$oa-audio: unquote('"\\e955"');
$oa-airplay: unquote('"\\e956"');
$oa-spinner-simple: unquote('"\\e94b"');
$oa-loader: unquote('"\\e94b"');
$oa-quality-uhd: unquote('"\\e95f"');
$oa-quality-8k: unquote('"\\e95e"');
$oa-dolby-vision-horizontal: unquote('"\\e943"');
$oa-dolby-atmos-horizontal: unquote('"\\e93f"');
$oa-dolby-vision-atmos-horizontal: unquote('"\\e95b"');
$oa-dolby-atmos-vision-horizontal: unquote('"\\e95b"');
$oa-dolby-icon: unquote('"\\e941"');
$oa-dolby-logo: unquote('"\\e942"');
$oa-quality-hd: unquote('"\\e945"');
$oa-quality-hdr: unquote('"\\e946"');
$oa-sound-5-1: unquote('"\\e947"');
$oa-vip: unquote('"\\e94a"');
$oa-settings: unquote('"\\e909"');
$oa-cog: unquote('"\\e909"');
$oa-logout: unquote('"\\e918"');
$oa-star: unquote('"\\e91b"');
$oa-cake: unquote('"\\e900"');
$oa-age6nl: unquote('"\\e901"');
$oa-age9nl: unquote('"\\e902"');
$oa-age12nl: unquote('"\\e903"');
$oa-age16nl: unquote('"\\e904"');
$oa-age18nl: unquote('"\\e905"');
$oa-account: unquote('"\\e906"');
$oa-user: unquote('"\\e906"');
$oa-addressbook: unquote('"\\e907"');
$oa-agealnl: unquote('"\\e908"');
$oa-calendar: unquote('"\\e90a"');
$oa-cart: unquote('"\\e90b"');
$oa-check: unquote('"\\e90c"');
$oa-close: unquote('"\\e90d"');
$oa-cross: unquote('"\\e90d"');
$oa-collapse: unquote('"\\e90e"');
$oa-minus: unquote('"\\e90e"');
$oa-arrow-down: unquote('"\\e90f"');
$oa-chevron-down: unquote('"\\e910"');
$oa-edit: unquote('"\\e911"');
$oa-email: unquote('"\\e912"');
$oa-expand: unquote('"\\e913"');
$oa-external: unquote('"\\e914"');
$oa-facebook: unquote('"\\e915"');
$oa-facebook1: unquote('"\\e916"');
$oa-filter: unquote('"\\e917"');
$oa-google: unquote('"\\e919"');
$oa-helpdesk: unquote('"\\e91a"');
$oa-home: unquote('"\\1f322"');
$oa-information: unquote('"\\e91c"');
$oa-instagram: unquote('"\\e91d"');
$oa-invoices: unquote('"\\e91e"');
$oa-language: unquote('"\\e91f"');
$oa-arrow-left: unquote('"\\e920"');
$oa-chevron-left: unquote('"\\e921"');
$oa-link: unquote('"\\e922"');
$oa-lock: unquote('"\\e923"');
$oa-menu: unquote('"\\e924"');
$oa-hamburger: unquote('"\\e924"');
$oa-notification: unquote('"\\e925"');
$oa-pause: unquote('"\\e926"');
$oa-play: unquote('"\\e927"');
$oa-question: unquote('"\\e928"');
$oa-question-circle: unquote('"\\e929"');
$oa-restart: unquote('"\\e92a"');
$oa-arrow-right: unquote('"\\e92b"');
$oa-chevron-right: unquote('"\\e92c"');
$oa-search: unquote('"\\e92d"');
$oa-share-apple: unquote('"\\e92f"');
$oa-show: unquote('"\\e930"');
$oa-eye: unquote('"\\e930"');
$oa-spinner: unquote('"\\e931"');
$oa-spotify: unquote('"\\e932"');
$oa-stop: unquote('"\\e933"');
$oa-subscription: unquote('"\\e934"');
$oa-card: unquote('"\\e934"');
$oa-subscriptions: unquote('"\\e935"');
$oa-stack: unquote('"\\e935"');
$oa-ticket: unquote('"\\e936"');
$oa-tiktok: unquote('"\\e937"');
$oa-chevron-up: unquote('"\\e938"');
$oa-chevron-up-down: unquote('"\\e93b"');
$oa-arrow-up: unquote('"\\e93c"');
$oa-warning: unquote('"\\e93d"');
$oa-danger: unquote('"\\e93d"');
$oa-youtube: unquote('"\\e93e"');
$oa-globe: unquote('"\\e9c9"');
$oa-share: unquote('"\\ea82"');
$oa-instagram1: unquote('"\\ea92"');
$oa-spotify1: unquote('"\\ea94"');
$oa-rss2: unquote('"\\ea9c"');
$oa-apple: unquote('"\\eabe"');
$oa-linkedin: unquote('"\\eaca"');
$oa-rss: unquote('"\\ea9b"');
$oa-youtube1: unquote('"\\ea9d"');
$oa-android: unquote('"\\eac0"');
$oa-soundcloud: unquote('"\\eac3"');
