.iconbadge {
  display: inline-flex;
  flex-direction: row;
  padding: 0 10px;
  margin-bottom: 10px;
  align-items: center;

  .iconbadge__badge {
    padding-top: 7px;
    margin-right: 10px;

    i {
      font-size: 32px;

      &:before {
        //color: $text-primary;
      }
    }
  }
}
