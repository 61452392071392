.loading-spinner {
  i.oa {
    display: block;
    animation: loading-spinner 1.4s infinite linear;
    transform-origin: center;

    &:before {
      top: 0;
    }
  }
}
