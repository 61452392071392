$card-title: 16px;
$card-title-line-height: 24px;
$card-title-mobile: 13px;
$card-title-mobile-line-height: 16px;

.card__title {
  font-family: var(--font-dm-sans);
  font-weight: $dm-sans-weight-medium;
  color: $text-primary;

  font-size: $card-title-mobile;
  line-height: $card-title-mobile-line-height;

  padding: 0;
  margin: 0;

  .oa {
    &:before {
      color: $text-primary;
    }
  }
}

@include media-breakpoint-up(lg) {
  .card__title {
    font-size: $card-title;
    line-height: $card-title-line-height;
  }
}
