.error-wrap {
  margin-top: 128px;
  display: flex;
  flex-direction: column;
}

@include media-breakpoint-down(lg) {
  .error-wrap {
    margin-top: 128px;
  }
}

@include media-breakpoint-down(md) {
  .error-wrap {
    margin-top: 96px;
  }
}

@include media-breakpoint-down(sm) {
  .error-wrap {
    margin-top: 96px;
  }
}

.error {
  .row {
    display: flex;
    flex-direction: row !important;

    span,
    h5,
    a {
      display: inline-flex;
      width: auto;
    }

    .colored {
      color: $primary-midtone;
    }
  }
}
