.ticket {
  // To make sure that the outline border gets the same border-radius as the picture
  // only relevant when the ticket is focused
  border-radius: 10px;
  padding-right: 8px;

  display: inline-block;
  .ticket__image {
    min-width: 136px;
    width: 136px;
    height: 80px;
    object-fit: cover;
  }
}

.ticket-skeleton {
  display: flex;
  flex-direction: row;
}

@include media-breakpoint-up(lg) {
  .ticket {
    .ticket__image {
      min-width: 240px;
      width: 240px;
      height: 144px;
    }
  }
}

@include media-breakpoint-up(xl) {
  .ticket-skeleton {
    .ticket-skeleton__title {
      width: 58%;
    }

    .ticket-skeleton__subtitle {
      width: 65%;
    }
  }
}
