.add-to-calendar {
  width: 60px;
  height: 60px;

  &__button {
    position: relative;
    width: 56px;
    height: 56px;
    background-color: transparent;
    border-radius: 100%;
    font-size: 24px;

    &:before {
      display: block;
      position: absolute;
      background: none;
      content: '';
      inset: 4px;
      border-radius: 100%;
      border: 2px solid $btn-outline-secondary-color;
    }

    &:focus-visible {
      outline-offset: 0px;
    }

    &:hover {
      cursor: pointer;
      &:before {
        border: 2px solid $btn-outline-secondary-hover-color;
        color: btn-outline-secondary-hover-color;
        transition: color 200ms ease-in-out;
      }

      .add-to-calendar__icon {
        &::before {
          color: $btn-outline-secondary-hover-color;
        }
      }
    }
  }
}

.add-to-calendar__dropdown {
  position: absolute;
  background-color: $shade-1;
  width: 343px;
  border-radius: 8px;
  z-index: 1;
  animation: popup 0.3s;

  .option {
    color: $input-color !important;
    padding: 12px 16px;
    width: 100%;
    border-width: 2px;
    border-style: solid;
    border-color: transparent;
    position: relative;

    &:focus-visible {
      outline-offset: -2px;
    }

    &:hover {
      cursor: pointer;
      border-left: 0px;
      .option__icon {
        margin-left: 2px;
      }
      &:before {
        content: '';
        background-color: $primary-midtone;
        width: 4px;
        height: 100%;
        position: absolute;
        left: 0;
      }
    }
  }

  @keyframes popup {
    0% {
      transform: scale(0.1);
    }
    100% {
      transform: scale(1);
    }
  }
}
