$page-banner-height: 73px;

.page-banner {
  width: 100%;
  position: fixed;
  top: 0;

  background-color: $error-midtone;
  display: flex;
  justify-content: center;
  text-align: center;
  color: $text-primary;
}

.platform-message-banner {
  @extend .page-banner;
  min-height: $page-banner-height;
  z-index: 101;
  align-items: center;

  .oa:before {
    color: $shade-white;
  }

  .close-icon {
    font-size: $legend-font-size;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 16px;
    cursor: pointer;
  }
}

.countdown-banner {
  @extend .page-banner;
  height: $page-banner-height;
  flex-direction: column;
  z-index: 10; // should be higher than the sticky event configurator

  .countdown-banner__countdown {
    font-size: 18px;
  }
}
