.event-configurator {
  margin-top: 16px;

  width: 100%;

  .event-configurator-option {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    margin-bottom: 8px;

    &:last-child {
      margin-right: 0;
    }

    .event-configurator-option {
      background-color: $accent-darkest;
      color: $text-primary;
      border: 2px solid $btn-outline-secondary-color;

      width: 216px;

      border-radius: 10px;
      padding: 12px;

      font-family: var(--font-dm-sans);
      font-weight: $dm-sans-weight-regular;

      font-size: 13px;
      line-height: 24px;
      text-align: center;

      transition:
        background-color 0.2s ease-in,
        border 0.2s ease-in,
        color 0.2s ease-in;

      &.option-value--active:hover,
      &:hover {
        cursor: pointer;
        background-color: $btn-outline-secondary-hover-color;
        border-color: $btn-outline-secondary-hover-color;
        color: $btn-solid-secondary-color;
        .event-configurator-option__icon {
          &::before {
            color: $btn-solid-secondary-color;
          }
        }
      }

      &.option-value--active {
        background-color: $btn-solid-secondary-bg;
        border-color: $btn-solid-secondary-bg;

        color: $btn-solid-secondary-color;
      }

      &.option-value--one-third {
        width: 33%;
      }

      &.option-value--half {
        width: 50%;
      }

      &.option-value--full {
        width: 100%;
      }

      &__icons {
        max-width: 170px;
        overflow-x: hidden;
      }

      &__icon {
        &--active {
          &::before {
            color: $shade-7;
          }
        }
        &::before {
          height: 32px;
          font-size: 32px;
        }

        &:not(:last-child) {
          margin-right: 8px;
        }
      }

      &:not(:last-child) {
        margin-right: 8px;
      }
    }
  }

  .event-configurator__button-and-price {
    margin-top: 16px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    .event-configurator__buy-button-wrapper {
      flex: 1;

      .btn-onair {
        margin-right: 8px;
      }
    }

    .event-configurator__price {
      @include section-title;
      font-family: var(--font-dm-sans);
      font-weight: $dm-sans-weight-bold;

      display: flex;
      flex-direction: column;
      text-align: right;
      flex-wrap: nowrap;
      margin-right: 16px;

      .event-configurator__before-price {
        @include caption-text;
      }

      i {
        color: $primary-midtone;
        text-decoration: line-through;
        font-style: normal;
      }
    }
  }

  h4 {
    color: $accent-lightest;
  }

  .login-to-see-your-ticket {
    @include caption-text;
    margin-top: 16px;
  }
}

.sticky-event-configurator {
  background-color: $shade-7;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.3);
  position: fixed;
  left: 0;
  width: 100%;
  padding: 16px 0;
  transform: translateY(-210px);
  z-index: 9;
  display: flex;
  align-items: center;

  &.sticky-mobile {
    bottom: -200px;
    transform: initial;
  }

  &__content {
    justify-content: space-between;
    align-items: center;
    flex-direction: column;

    .wrapper {
      justify-content: space-between;
      align-items: center;
    }

    .left-column,
    .right-column {
      padding: 0;
    }

    .event-title {
      font-size: 16px;
      margin-bottom: 12px;
      font-family: var(--font-dm-sans);
      font-weight: $dm-sans-weight-bold;
    }

    .dot {
      width: 5px;
      height: 5px;
      background-color: $shade-3;
      border-radius: 50%;
      margin: 0 20px;
    }

    .event-info {
      display: flex;
      align-items: center;
    }

    .icons {
      display: flex;
      margin-right: 15px;

      i:not(:last-child) {
        margin-right: 10px;
      }
    }

    .event-date {
      @include body-text;
      color: $shade-3;
    }

    .event-price {
      font-size: 22px;
      font-weight: 700;
      color: $primary-midtone;
    }
  }
}
.loading-event-info {
  opacity: 0;
}

@include media-breakpoint-down(md) {
  .sticky-event-configurator:not(.sticky-mobile) {
    margin-top: -$spacer-1-value;
  }
}

@include media-breakpoint-up(sm) {
  .event-configurator {
    width: 448px;
  }
}

@include media-breakpoint-up(md) {
  .event-configurator {
    width: 456px;
  }
  .event-configurator
    .event-configurator-option
    .event-configurator-option-value {
    font-size: 16px;
  }

  .sticky-event-configurator {
    &__content {
      flex-direction: row;

      .icons {
        margin-right: 0;
      }
    }
  }
  .event-configurator {
    .event-configurator__button-and-price {
      .event-configurator__price {
        flex-direction: row;
        margin-right: 20px;

        .event-configurator__before-price {
          font-size: 18px;
          line-height: 24px;
          margin-right: 16px;
        }
      }
    }

    .login-to-see-your-ticket {
      @include body-title;
      margin-top: 16px;
    }
  }
}

@include media-breakpoint-up(lg) {
  .event-configurator {
    width: 496px;
  }
}
