.container--vertical-padding {
  padding-top: 32px;
  padding-bottom: 32px;
}

.first-block {
  position: relative;
  background-color: transparent;
  margin-top: -18px;
  z-index: 1;
}

@include media-breakpoint-up(sm) {
  .first-block {
    margin-top: -60px;
  }
}

@include media-breakpoint-up(md) {
  .container--vertical-padding {
    padding-top: 64px;
    padding-bottom: 64px;
  }

  .first-block {
    margin-top: -272px;
  }
}

@include media-breakpoint-up(lg) {
  .first-block {
    margin-top: -336px;
  }
}

@include media-breakpoint-up(xxl) {
  .first-block {
    margin-top: -176px;
  }
}
