@import 'variables';

@font-face {
  font-family: '#{$icomoon-font-family}';
  src: url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?jmpibd');
  src:
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?jmpibd#iefix')
      format('embedded-opentype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.ttf?jmpibd')
      format('truetype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff?jmpibd')
      format('woff'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.svg?jmpibd##{$icomoon-font-family}')
      format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

.oa {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: '#{$icomoon-font-family}' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.oa-no-play-large {
  &:before {
    content: $oa-no-play-large;
  }
}
.oa-harmonica {
  &:before {
    content: $oa-harmonica;
  }
}
.oa-saxophone {
  &:before {
    content: $oa-saxophone;
  }
}
.oa-rocket {
  &:before {
    content: $oa-rocket;
  }
}
.oa-play-large {
  &:before {
    content: $oa-play-large;
  }
}
.oa-music-notes {
  &:before {
    content: $oa-music-notes;
  }
}
.oa-globe-large {
  &:before {
    content: $oa-globe-large;
  }
}
.oa-cup-large {
  &:before {
    content: $oa-cup-large;
  }
}
.oa-stage {
  &:before {
    content: $oa-stage;
  }
}
.oa-audio-large {
  &:before {
    content: $oa-audio-large;
  }
}
.oa-cinema-set-large {
  &:before {
    content: $oa-cinema-set-large;
  }
}
.oa-home-large {
  &:before {
    content: $oa-home-large;
  }
}
.oa-lightning-large {
  &:before {
    content: $oa-lightning-large;
  }
}
.oa-quote-large {
  &:before {
    content: $oa-quote-large;
  }
}
.oa-sliders-large {
  &:before {
    content: $oa-sliders-large;
  }
}
.oa-star-large {
  &:before {
    content: $oa-star-large;
  }
}
.oa-team-large {
  &:before {
    content: $oa-team-large;
  }
}
.oa-thought-large {
  &:before {
    content: $oa-thought-large;
  }
}
.oa-ticket-large {
  &:before {
    content: $oa-ticket-large;
  }
}
.oa-tv-large {
  &:before {
    content: $oa-tv-large;
  }
}
.oa-user-large {
  &:before {
    content: $oa-user-large;
  }
}
.oa-warning-large {
  &:before {
    content: $oa-warning-large;
  }
}
.oa-electric-guitar {
  &:before {
    content: $oa-electric-guitar;
  }
}
.oa-hiphop-cap {
  &:before {
    content: $oa-hiphop-cap;
  }
}
.oa-browser {
  &:before {
    content: $oa-browser;
  }
}
.oa-tourbus {
  &:before {
    content: $oa-tourbus;
  }
}
.oa-video-camera {
  &:before {
    content: $oa-video-camera;
  }
}
.oa-smartphone {
  &:before {
    content: $oa-smartphone;
  }
}
.oa-banjo {
  &:before {
    content: $oa-banjo;
  }
}
.oa-vinyl-player {
  &:before {
    content: $oa-vinyl-player;
  }
}
.oa-emotion-faces {
  &:before {
    content: $oa-emotion-faces;
  }
}
.oa-guitar {
  &:before {
    content: $oa-guitar;
  }
}
.oa-headphones {
  &:before {
    content: $oa-headphones;
  }
}
.oa-trumpet {
  &:before {
    content: $oa-trumpet;
  }
}
.oa-microphone {
  &:before {
    content: $oa-microphone;
  }
}
.oa-speaker {
  &:before {
    content: $oa-speaker;
  }
}
.oa-vintage-microphone {
  &:before {
    content: $oa-vintage-microphone;
  }
}
.oa-violin {
  &:before {
    content: $oa-violin;
  }
}
.oa-volume-bars {
  &:before {
    content: $oa-volume-bars;
  }
}
.oa-exit-fullscreen {
  &:before {
    content: $oa-exit-fullscreen;
  }
}
.oa-pip-off {
  &:before {
    content: $oa-pip-off;
  }
}
.oa-speed {
  &:before {
    content: $oa-speed;
  }
}
.oa-caption {
  &:before {
    content: $oa-caption;
  }
}
.oa-not-playable {
  &:before {
    content: $oa-not-playable;
  }
}
.oa-pip {
  &:before {
    content: $oa-pip;
  }
}
.oa-phone-small {
  &:before {
    content: $oa-phone-small;
  }
}
.oa-pin {
  &:before {
    content: $oa-pin;
  }
}
.oa-dolby-atmos-vertical {
  &:before {
    content: $oa-dolby-atmos-vertical;
  }
}
.oa-dolby-atmos-vision-vertical {
  &:before {
    content: $oa-dolby-atmos-vision-vertical;
  }
}
.oa-dolby-vision-atmos-vertical {
  &:before {
    content: $oa-dolby-vision-atmos-vertical;
  }
}
.oa-dolby-vision-vertical {
  &:before {
    content: $oa-dolby-vision-vertical;
  }
}
.oa-4k-vertical {
  &:before {
    content: $oa-4k-vertical;
  }
}
.oa-8k-vertical {
  &:before {
    content: $oa-8k-vertical;
  }
}
.oa-surround-vertical {
  &:before {
    content: $oa-surround-vertical;
  }
}
.oa-ambilight-vertical {
  &:before {
    content: $oa-ambilight-vertical;
  }
}
.oa-hd-vertical {
  &:before {
    content: $oa-hd-vertical;
  }
}
.oa-hdr-vertical {
  &:before {
    content: $oa-hdr-vertical;
  }
}
.oa-sd-vertical {
  &:before {
    content: $oa-sd-vertical;
  }
}
.oa-sd {
  &:before {
    content: $oa-sd;
  }
}
.oa-stereo-vertical {
  &:before {
    content: $oa-stereo-vertical;
  }
}
.oa-stream {
  &:before {
    content: $oa-stream;
  }
}
.oa-sound-stereo {
  &:before {
    content: $oa-sound-stereo;
  }
}
.oa-accessibility {
  &:before {
    content: $oa-accessibility;
  }
}
.oa-lightningbolt {
  &:before {
    content: $oa-lightningbolt;
  }
}
.oa-handshake {
  &:before {
    content: $oa-handshake;
  }
}
.oa-trend-up {
  &:before {
    content: $oa-trend-up;
  }
}
.oa-backspace {
  &:before {
    content: $oa-backspace;
  }
}
.oa-categories {
  &:before {
    content: $oa-categories;
  }
}
.oa-cloud {
  &:before {
    content: $oa-cloud;
  }
}
.oa-space {
  &:before {
    content: $oa-space;
  }
}
.oa-thread {
  &:before {
    content: $oa-thread;
  }
}
.oa-twitter {
  &:before {
    content: $oa-twitter;
  }
}
.oa-printer {
  &:before {
    content: $oa-printer;
  }
}
.oa-firetv {
  &:before {
    content: $oa-firetv;
  }
}
.oa-youbora {
  &:before {
    content: $oa-youbora;
  }
}
.oa-camera {
  &:before {
    content: $oa-camera;
  }
}
.oa-tv {
  &:before {
    content: $oa-tv;
  }
}
.oa-clock {
  &:before {
    content: $oa-clock;
  }
}
.oa-columns {
  &:before {
    content: $oa-columns;
  }
}
.oa-sort {
  &:before {
    content: $oa-sort;
  }
}
.oa-ambilight {
  &:before {
    content: $oa-ambilight;
  }
}
.oa-copy {
  &:before {
    content: $oa-copy;
  }
}
.oa-payment-card {
  &:before {
    content: $oa-payment-card;
  }
}
.oa-tag {
  &:before {
    content: $oa-tag;
  }
}
.oa-cinema-set {
  &:before {
    content: $oa-cinema-set;
  }
}
.oa-cup {
  &:before {
    content: $oa-cup;
  }
}
.oa-calendar-add {
  &:before {
    content: $oa-calendar-add;
  }
}
.oa-dots {
  &:before {
    content: $oa-dots;
  }
}
.oa-blocked {
  &:before {
    content: $oa-blocked;
  }
}
.oa-gift {
  &:before {
    content: $oa-gift;
  }
}
.oa-jumpforward {
  &:before {
    content: $oa-jumpforward;
  }
}
.oa-jumpback {
  &:before {
    content: $oa-jumpback;
  }
}
.oa-60secforward {
  &:before {
    content: $oa-60secforward;
  }
}
.oa-60secback {
  &:before {
    content: $oa-60secback;
  }
}
.oa-imdb {
  &:before {
    content: $oa-imdb;
  }
}
.oa-check-circle {
  &:before {
    content: $oa-check-circle;
  }
}
.oa-spinner-simple-large {
  &:before {
    content: $oa-spinner-simple-large;
  }
}
.oa-loudspeaker-loud {
  &:before {
    content: $oa-loudspeaker-loud;
  }
}
.oa-exclamation-mark {
  &:before {
    content: $oa-exclamation-mark;
  }
}
.oa-upload {
  &:before {
    content: $oa-upload;
  }
}
.oa-download {
  &:before {
    content: $oa-download;
  }
}
.oa-quote {
  &:before {
    content: $oa-quote;
  }
}
.oa-music-note-1 {
  &:before {
    content: $oa-music-note-1;
  }
}
.oa-music-note-2 {
  &:before {
    content: $oa-music-note-2;
  }
}
.oa-secback {
  &:before {
    content: $oa-secback;
  }
}
.oa-10secback {
  &:before {
    content: $oa-10secback;
  }
}
.oa-secforward {
  &:before {
    content: $oa-secforward;
  }
}
.oa-10secforward {
  &:before {
    content: $oa-10secforward;
  }
}
.oa-thought {
  &:before {
    content: $oa-thought;
  }
}
.oa-onetree3 {
  &:before {
    content: $oa-onetree3;
  }
}
.oa-onetree {
  &:before {
    content: $oa-onetree;
  }
}
.oa-onetree2 {
  &:before {
    content: $oa-onetree2;
  }
}
.oa-trashcan {
  &:before {
    content: $oa-trashcan;
  }
}
.oa-film {
  &:before {
    content: $oa-film;
  }
}
.oa-lighting {
  &:before {
    content: $oa-lighting;
  }
}
.oa-hearth {
  &:before {
    content: $oa-hearth;
  }
}
.oa-speech-bubble {
  &:before {
    content: $oa-speech-bubble;
  }
}
.oa-retry {
  &:before {
    content: $oa-retry;
  }
}
.oa-star-filled {
  &:before {
    content: $oa-star-filled;
  }
}
.oa-team {
  &:before {
    content: $oa-team;
  }
}
.oa-fastforward {
  &:before {
    content: $oa-fastforward;
  }
}
.oa-rewind {
  &:before {
    content: $oa-rewind;
  }
}
.oa-live {
  &:before {
    content: $oa-live;
  }
}
.oa-loudspeaker-low {
  &:before {
    content: $oa-loudspeaker-low;
  }
}
.oa-volume-down {
  &:before {
    content: $oa-volume-down;
  }
}
.oa-loudspeaker-off {
  &:before {
    content: $oa-loudspeaker-off;
  }
}
.oa-mute {
  &:before {
    content: $oa-mute;
  }
}
.oa-loudspeaker {
  &:before {
    content: $oa-loudspeaker;
  }
}
.oa-tiktok2 {
  &:before {
    content: $oa-tiktok2;
  }
}
.oa-wifi-warning {
  &:before {
    content: $oa-wifi-warning;
  }
}
.oa-eye-closed {
  &:before {
    content: $oa-eye-closed;
  }
}
.oa-hide {
  &:before {
    content: $oa-hide;
  }
}
.oa-sliders {
  &:before {
    content: $oa-sliders;
  }
}
.oa-frame {
  &:before {
    content: $oa-frame;
  }
}
.oa-full-screen {
  &:before {
    content: $oa-full-screen;
  }
}
.oa-chromecast {
  &:before {
    content: $oa-chromecast;
  }
}
.oa-chromecast-casting {
  &:before {
    content: $oa-chromecast-casting;
  }
}
.oa-audio {
  &:before {
    content: $oa-audio;
  }
}
.oa-airplay {
  &:before {
    content: $oa-airplay;
  }
}
.oa-spinner-simple {
  &:before {
    content: $oa-spinner-simple;
  }
}
.oa-loader {
  &:before {
    content: $oa-loader;
  }
}
.oa-quality-uhd {
  &:before {
    content: $oa-quality-uhd;
  }
}
.oa-quality-8k {
  &:before {
    content: $oa-quality-8k;
  }
}
.oa-dolby-vision-horizontal {
  &:before {
    content: $oa-dolby-vision-horizontal;
  }
}
.oa-dolby-atmos-horizontal {
  &:before {
    content: $oa-dolby-atmos-horizontal;
  }
}
.oa-dolby-vision-atmos-horizontal {
  &:before {
    content: $oa-dolby-vision-atmos-horizontal;
  }
}
.oa-dolby-atmos-vision-horizontal {
  &:before {
    content: $oa-dolby-atmos-vision-horizontal;
  }
}
.oa-dolby-icon {
  &:before {
    content: $oa-dolby-icon;
  }
}
.oa-dolby-logo {
  &:before {
    content: $oa-dolby-logo;
  }
}
.oa-quality-hd {
  &:before {
    content: $oa-quality-hd;
  }
}
.oa-quality-hdr {
  &:before {
    content: $oa-quality-hdr;
  }
}
.oa-sound-5-1 {
  &:before {
    content: $oa-sound-5-1;
  }
}
.oa-vip {
  &:before {
    content: $oa-vip;
  }
}
.oa-settings {
  &:before {
    content: $oa-settings;
  }
}
.oa-cog {
  &:before {
    content: $oa-cog;
  }
}
.oa-logout {
  &:before {
    content: $oa-logout;
  }
}
.oa-star {
  &:before {
    content: $oa-star;
  }
}
.oa-cake {
  &:before {
    content: $oa-cake;
  }
}
.oa-age6nl {
  &:before {
    content: $oa-age6nl;
  }
}
.oa-age9nl {
  &:before {
    content: $oa-age9nl;
  }
}
.oa-age12nl {
  &:before {
    content: $oa-age12nl;
  }
}
.oa-age16nl {
  &:before {
    content: $oa-age16nl;
  }
}
.oa-age18nl {
  &:before {
    content: $oa-age18nl;
  }
}
.oa-account {
  &:before {
    content: $oa-account;
  }
}
.oa-user {
  &:before {
    content: $oa-user;
  }
}
.oa-addressbook {
  &:before {
    content: $oa-addressbook;
  }
}
.oa-agealnl {
  &:before {
    content: $oa-agealnl;
  }
}
.oa-calendar {
  &:before {
    content: $oa-calendar;
  }
}
.oa-cart {
  &:before {
    content: $oa-cart;
  }
}
.oa-check {
  &:before {
    content: $oa-check;
  }
}
.oa-close {
  &:before {
    content: $oa-close;
  }
}
.oa-cross {
  &:before {
    content: $oa-cross;
  }
}
.oa-collapse {
  &:before {
    content: $oa-collapse;
  }
}
.oa-minus {
  &:before {
    content: $oa-minus;
  }
}
.oa-arrow-down {
  &:before {
    content: $oa-arrow-down;
  }
}
.oa-chevron-down {
  &:before {
    content: $oa-chevron-down;
  }
}
.oa-edit {
  &:before {
    content: $oa-edit;
  }
}
.oa-email {
  &:before {
    content: $oa-email;
  }
}
.oa-expand {
  &:before {
    content: $oa-expand;
  }
}
.oa-external {
  &:before {
    content: $oa-external;
  }
}
.oa-facebook {
  &:before {
    content: $oa-facebook;
  }
}
.oa-facebook1 {
  &:before {
    content: $oa-facebook1;
  }
}
.oa-filter {
  &:before {
    content: $oa-filter;
  }
}
.oa-google {
  &:before {
    content: $oa-google;
  }
}
.oa-helpdesk {
  &:before {
    content: $oa-helpdesk;
  }
}
.oa-home {
  &:before {
    content: $oa-home;
  }
}
.oa-information {
  &:before {
    content: $oa-information;
  }
}
.oa-instagram {
  &:before {
    content: $oa-instagram;
  }
}
.oa-invoices {
  &:before {
    content: $oa-invoices;
  }
}
.oa-language {
  &:before {
    content: $oa-language;
  }
}
.oa-arrow-left {
  &:before {
    content: $oa-arrow-left;
  }
}
.oa-chevron-left {
  &:before {
    content: $oa-chevron-left;
  }
}
.oa-link {
  &:before {
    content: $oa-link;
  }
}
.oa-lock {
  &:before {
    content: $oa-lock;
  }
}
.oa-menu {
  &:before {
    content: $oa-menu;
  }
}
.oa-hamburger {
  &:before {
    content: $oa-hamburger;
  }
}
.oa-notification {
  &:before {
    content: $oa-notification;
  }
}
.oa-pause {
  &:before {
    content: $oa-pause;
  }
}
.oa-play {
  &:before {
    content: $oa-play;
  }
}
.oa-question {
  &:before {
    content: $oa-question;
  }
}
.oa-question-circle {
  &:before {
    content: $oa-question-circle;
  }
}
.oa-restart {
  &:before {
    content: $oa-restart;
  }
}
.oa-arrow-right {
  &:before {
    content: $oa-arrow-right;
  }
}
.oa-chevron-right {
  &:before {
    content: $oa-chevron-right;
  }
}
.oa-search {
  &:before {
    content: $oa-search;
  }
}
.oa-share-apple {
  &:before {
    content: $oa-share-apple;
  }
}
.oa-show {
  &:before {
    content: $oa-show;
  }
}
.oa-eye {
  &:before {
    content: $oa-eye;
  }
}
.oa-spinner {
  &:before {
    content: $oa-spinner;
  }
}
.oa-spotify {
  &:before {
    content: $oa-spotify;
  }
}
.oa-stop {
  &:before {
    content: $oa-stop;
  }
}
.oa-subscription {
  &:before {
    content: $oa-subscription;
  }
}
.oa-card {
  &:before {
    content: $oa-card;
  }
}
.oa-subscriptions {
  &:before {
    content: $oa-subscriptions;
  }
}
.oa-stack {
  &:before {
    content: $oa-stack;
  }
}
.oa-ticket {
  &:before {
    content: $oa-ticket;
  }
}
.oa-tiktok {
  &:before {
    content: $oa-tiktok;
  }
}
.oa-chevron-up {
  &:before {
    content: $oa-chevron-up;
  }
}
.oa-chevron-up-down {
  &:before {
    content: $oa-chevron-up-down;
  }
}
.oa-arrow-up {
  &:before {
    content: $oa-arrow-up;
  }
}
.oa-warning {
  &:before {
    content: $oa-warning;
  }
}
.oa-danger {
  &:before {
    content: $oa-danger;
  }
}
.oa-youtube {
  &:before {
    content: $oa-youtube;
  }
}
.oa-globe {
  &:before {
    content: $oa-globe;
  }
}
.oa-share {
  &:before {
    content: $oa-share;
  }
}
.oa-instagram1 {
  &:before {
    content: $oa-instagram1;
  }
}
.oa-spotify1 {
  &:before {
    content: $oa-spotify1;
  }
}
.oa-rss2 {
  &:before {
    content: $oa-rss2;
  }
}
.oa-apple {
  &:before {
    content: $oa-apple;
  }
}
.oa-linkedin {
  &:before {
    content: $oa-linkedin;
  }
}
.oa-rss {
  &:before {
    content: $oa-rss;
  }
}
.oa-youtube1 {
  &:before {
    content: $oa-youtube1;
  }
}
.oa-android {
  &:before {
    content: $oa-android;
  }
}
.oa-soundcloud {
  &:before {
    content: $oa-soundcloud;
  }
}
