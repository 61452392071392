.onair-date-input {
  .onair-dropdown,
  .onair-date-input__day {
    padding-right: 16px !important;
  }

  .onair-dropdown {
    flex: 2;
  }

  .onair-date-input__day {
    flex: 1;
  }

  .onair-date-input__year {
    flex: 1.2;
  }
}

@include media-breakpoint-up(lg) {
  .onair-date-input {
    .onair-dropdown {
      flex: 3;
    }
  }
}

@include media-breakpoint-up(xxl) {
  .onair-date-input {
    .onair-dropdown {
      flex: 4;
    }
  }
}
