.gallery-1 {
  position: relative;
  --num-cols: 1;
  --row-height: 200px;

  box-sizing: border-box;

  display: grid;
  grid-template-columns: repeat(var(--num-cols), 1fr);
  grid-auto-rows: var(--row-height);
  grid-gap: $spacer-2-value;

  &__item {
    display: flex;
    width: 100%;
    height: 100%;

    img {
      width: 100%;
      height: 100%;
    }
  }

  @include media-breakpoint-up(sm) {
    --row-height: 320px;
    grid-auto-rows: var(--row-height);
  }

  @include media-breakpoint-up(sm) {
    --row-height: 468px;
    grid-auto-rows: var(--row-height);
  }
}

@include media-breakpoint-up(xl) {
  .gallery-1 {
    height: 648px;
  }
}

.gallery-2 {
  position: relative;
  --num-cols: 1;
  --row-height: 200px;

  box-sizing: border-box;

  display: grid;
  grid-template-columns: repeat(var(--num-cols), 1fr);
  grid-auto-rows: var(--row-height);
  grid-gap: $spacer-2-value;

  &__item {
    width: 100%;
    height: 100%;
    object-fit: cover;

    .image-container {
      width: 100%;
      height: 100%;
      position: relative;
    }
  }
}

@include media-breakpoint-up(md) {
  .gallery-2 {
    --num-cols: 3;

    &__item {
      &:first-child {
        grid-column: span 2;
        grid-row: span 2;
      }
      &:nth-child(2) {
        grid-column: span 1;
        grid-row: span 2;
      }
    }
  }
}

@include media-breakpoint-up(lg) {
  .gallery-2 {
    grid-gap: $spacer-3-value;
    --row-height: 250px;
  }
}

.gallery-3 {
  position: relative;
  --num-cols: 1;
  --row-height: 200px;

  box-sizing: border-box;

  display: grid;
  grid-template-columns: repeat(var(--num-cols), 1fr);
  grid-auto-rows: var(--row-height);
  grid-gap: $spacer-2-value;

  &__item {
    width: 100%;
    height: 100%;
    object-fit: cover;

    .image-container {
      width: 100%;
      height: 100%;
      position: relative;
    }
  }
}

@include media-breakpoint-up(md) {
  .gallery-3 {
    --num-cols: 2;

    &__item {
      &:first-child {
        grid-column: span 2;
        grid-row: span 2;
      }
    }
  }
}

@include media-breakpoint-up(lg) {
  .gallery-3 {
    --num-cols: 3;
    --row-height: 250px;
    grid-gap: $spacer-3-value;
  }
}
