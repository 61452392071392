.capitalize {
  text-transform: capitalize;
}

.uppercase-first-letter {
  display: block;
  &::first-letter {
    text-transform: uppercase;
  }
}

.uppercase {
  text-transform: uppercase;
}
