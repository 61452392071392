.search-wrapper {
  position: relative;
  .navbar__search {
    position: relative;
    flex: 1;

    font-family: var(--font-dm-sans);
    font-size: 16px;
    line-height: 24px;

    input {
      width: 100%;
      padding: 12px 12px 12px 45px;
      border-radius: 20px;
      border: solid 0px;
      overflow: hidden;
      color: $input-color;

      &:focus,
      &:focus-visible {
        outline: solid 2px $primary-midtone;
        outline-offset: inherit;
      }
    }
  }

  &__search-addon {
    &:before {
      display: block;
      position: absolute;
      content: $oa-search;
      font-family: $icomoon-font-family;
      color: $accent-midtone;

      font-size: 24px;
      line-height: 24px;
      padding: 8px;
      left: 8px;
    }
  }
  &__search-clear {
    display: block;
    position: absolute;

    line-height: 0px;
    padding: 0px 12px 0px 0;
    border: none !important;
    top: 50%;
    transform: translateY(-50%);
    right: 0px;
  }
}

.search-results {
  position: relative;
  margin-top: 32px;

  width: 100%;

  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(165px, 1fr));
  grid-gap: 24px;

  .carousel__item {
    box-sizing: border-box;
    scroll-snap-align: start;
    transition:
      transform 0.3s ease,
      border-color 0.2s ease;

    flex: 0 0 184px;
    height: 276px;

    border-radius: $carousel-border-radius;
    overflow: hidden;
    // Trick for border-radius to work on macOs devices
    perspective: 1px;
    border: 2px solid transparent;

    object-fit: contain;

    a {
      display: block;
      height: 100%;
      width: 100%;
    }

    &:hover {
      cursor: pointer;
      border-color: $shade-1;
      box-shadow: 0 0 10px $shade-7;
    }

    .event-content {
      height: 100%;
      width: 100%;

      background-repeat: no-repeat;
      background-size: cover;
      background-position-x: 50%;

      display: flex;
      flex-direction: column-reverse;
      text-align: center;

      &__container {
        padding: 16px !important;
        margin-bottom: 0px !important;

        flex-direction: column-reverse;
        .event__card-subline {
          display: none;
        }
      }

      .logo-img {
        position: relative;
        height: auto;
        margin: 0 auto ($spacer-1-value - $spacer-1-value);
        img {
          width: auto !important;
          max-width: 100% !important;
          max-height: 48px;
          position: relative !important;
        }
      }

      h6 {
        margin: 0;
        position: relative; // Make sure it pops over shadow with absolute
        font-weight: $dm-sans-weight-bold;
        bottom: 0;
        white-space: normal;
        font-size: 11px;
      }

      .event__card-subline {
        position: relative; // Make sure it pops over shadow with absolute
        align-items: center;
        margin-top: 4px;
        font-size: 11px;
        line-height: $caption-text-line-height;
        margin-bottom: 0 !important;

        i {
          position: relative;
          top: -1px;
          margin-right: 8px;

          &:before {
            color: $text-primary;
            font-size: 16px;
          }
        }
      }

      &__short-text {
        @include caption-title();
        white-space: pre-wrap;
        position: relative;
        text-align: center;
        width: 100%;
        opacity: 1;
        animation: fadeAnimation 0.6s ease;
      }

      &__short-text-no-date {
        @extend .event-content__short-text;
        position: absolute;
        left: 50%;
        bottom: 15px;
        width: 90%;
        transform: translate(-50%, 0);
      }
    }
  }
}

.search-page {
  .event-content__container {
    padding: 16px !important;
    margin-bottom: 0px !important;
    .event__card-subline {
      display: none;
    }
  }
}

@include media-breakpoint-up(lg) {
  .search-wrapper {
    .navbar__search {
      margin-right: 16px;
    }
  }
}

@keyframes fadeAnimation {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@include media-breakpoint-up(xs) {
  .search-results {
    grid-gap: 8px;
  }
}
@include media-breakpoint-up(md) {
  .search-results {
    grid-gap: 24px;
  }
}
@include media-breakpoint-up(xl) {
  .search-results {
    grid-gap: 24px;
  }
}

@media (hover: none) {
  .search-results-wrapper__item__short-text {
    display: block !important;
  }
}
