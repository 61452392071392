// VENDOR OVERRIDES
$icomoon-font-path: './vendor/onair-icons/fonts';

$spacer-0-value: 0;
$spacer-1-value: 8px;
$spacer-2-value: 16px;
$spacer-25-value: 20px;
$spacer-3-value: 24px;
$spacer-4-value: 32px;
$spacer-5-value: 40px;
$spacer-6-value: 48px;
$spacer-7-value: 64px;
$spacer-8-value: 80px;
$spacer-9-value: 96px;
$spacer-10-value: 112px;
$spacer-11-value: 120px;

$spacers: (
  0: $spacer-0-value,
  1: $spacer-1-value,
  2: $spacer-2-value,
  3: $spacer-3-value,
  4: $spacer-4-value,
  5: $spacer-5-value,
  6: $spacer-6-value,
  7: $spacer-7-value,
  8: $spacer-8-value,
  9: $spacer-9-value,
  10: $spacer-10-value,
  11: $spacer-11-value,
);

// Bootstrap container widths overrides (for now default, but we need access)
$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1320px,
);

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px,
  fullHd: 1920px,
);

// ======== BRAND / THEME =========
// PRIMARY COLOR
$primary-darkest: #610e06;
$primary-dark: #aa1c0e;
$primary-midtone: #fc4d3c;
$primary-light: #ff7b6f;
$primary-lightest: #ffafa7;

// SECONDARY COLOR
$secondary-darkest: #170661;
$secondary-dark: #3618bc;
$secondary-midtone: #5f3cfc;
$secondary-light: #967eff;
$secondary-lightest: #c1b4fc;

// ACCENT COLOR
$accent-darkest: rgba(18, 17, 18, 0.25);
$accent-dark: rgba(18, 17, 18, 0.5);
$accent-midtone: rgba(18, 17, 18, 0.75);
$accent-light: rgba(227, 227, 227, 0.5);
$accent-lightest: rgba(227, 227, 227, 0.75);

// ======== SHADES =========
$shade-black: #000000;
$shade-7: #121112;
$shade-6: #19181a;
$shade-5: #343336;
$shade-4: #58565a;
$shade-3: #bcb8bf;
$shade-2: #e3e1e5;
$shade-1: #f7f5f8;
$shade-white: #ffffff;

// ======== FEEDBACK =========
// SUCCESS COLOR
$success-darkest: #06441c;
$success-dark: #118038;
$success-midtone: #2ac15f;
$success-light: #71e299;
$success-lightest: #c5f1d5;

// INFO COLOR
$info-darkest: #4f9ef8;
$info-dark: #89befa;
$info-midtone: #b9d8fc;
$info-light: #d8e9fd;
$info-lightest: #ebf4fe;

// INFO NEUTRAL COLOR
$info-neutral-dark: $shade-4;
$info-neutral-light: $shade-1;

// WARNING COLOR
$warning-darkest: #856e0a;
$warning-dark: #be9e0e;
$warning-midtone: #edc718;
$warning-light: #f4d467;
$warning-lightest: #f9ecb3;

// ERROR COLOR
$error-darkest: #60090c;
$error-dark: #8d0b0f;
$error-midtone: #fd4249;
$error-light: #ff7075;
$error-lightest: #ffadb0;

// ======== OVERLAYS =========
// BG COLOR
$bg-primary: $shade-6;
$bg-secondary: #f1f1f1;
$bg-dark: $shade-7;
$bg-transparent-80: rgba(25, 24, 26, 0.8);
$bg-transparent-50: rgba(25, 24, 26, 0.5);
$bg-popover: #353436;

// ======== TEXT =========
$text-primary: $shade-1;
$text-secondary: $shade-7;

// ======== GRADIENTS =========
$gradient-orange-begin: $primary-midtone;
$gradient-orange-end: #f78443;

// ======== INPUT =========
$input-bg: #f1f1f1;
$input-bg-active: #f6f6f6;
$input-border-color: #e3e3e3;
$input-color: #19181a;
$input-placeholder: rgba(18, 17, 18, 0.5);

// ======== DISABLED =========
$disabled-bg: #bcb8bf;
$disabled-color: #58565a;
$disabled-border-color: #58565a;

$disabled-outline-bg: transparent;
$disabled-outline-color: #58565a;
$disabled-outline-border-color: #58565a;

// ======== BUTTONS ========
$btn-solid-primary-bg: #fc4d3c;
$btn-solid-primary-color: #f7f5f8;
$btn-solid-primary-hover-bg: #aa1c0e;
$btn-solid-primary-pressed-bg: #ff7b6f;

$btn-outline-primary-color: #fc4d3c;
$btn-outline-primary-hover-color: #aa1c0e;
$btn-outline-primary-pressed-color: #ff7b6f;

$btn-solid-secondary-bg: #e3e1e5;
$btn-solid-secondary-color: #19181a;
$btn-solid-secondary-hover-bg: #bcb8bf;
$btn-solid-secondary-pressed-bg: #f7f5f8;

$btn-outline-secondary-color: #e3e1e5;
$btn-outline-secondary-hover-color: #bcb8bf;
$btn-outline-secondary-pressed-color: #19181a;

// ======== NAVIGATION ========
$navigation-link-color: #f1f1f1;

// ======== HEADER HEIGHTS ========
$header-height: 56px;
$header-height-mobile: 48px;

// ======== BORDERS ========
$border-dark: #9b9a9c;
