.onair-input__wrapper {
  .onair-input__label-wrap {
    margin-top: 8px;
    align-items: center;
  }

  .onair-input__label {
    display: block;
    &::first-letter {
      text-transform: uppercase;
    }
  }

  .oa {
    &.input-icon--left {
      position: absolute;
      margin-left: 14px;
      top: 50%;
      transform: translateY(-50%);
    }

    &:before {
      transition: color 200ms ease-in-out;
      color: $input-color;
      font-size: 24px;
    }
  }

  .oa.input-icon--left {
    &:before {
      transition: color 200ms ease-in-out;
      color: $disabled-border-color;
      font-size: 24px;
    }
  }

  &.onair-input--with-icon input {
    padding-left: 42px;
  }

  &.onair-input--password input {
    padding-right: 42px;
  }

  &.onair-input--no-padding {
    padding: 0;
  }

  .onair-input__optional {
    color: rgba($text-primary, 0.75);
    float: right;
    line-height: inherit;
  }
}

.onair-input__field-container {
  position: relative;
  margin: 8px 0 0;
  .onair-input__eye-button {
    border-radius: 50%;
    cursor: pointer;
    position: absolute;
    margin: 0;
    top: 50%;
    transform: translateY(-50%);
    right: 16px;
    // Otherwise the button will get an egg shape when focused 🥚
    padding: 0px 1.75px;
  }

  input,
  textarea {
    font-family: var(--font-dm-sans);
    font-size: 16px;
    font-weight: $dm-sans-weight-regular;
    line-height: 24px;
    color: $input-color;

    background-color: $input-bg;
    border-color: $shade-3;
    border-radius: 8px;
    border-style: solid;

    padding: 12px 16px;
    width: 100%;
    box-sizing: border-box;
    border-width: 2px;

    transition: border-color 200ms ease-in-out;

    &:focus,
    &:hover {
      border-color: $primary-midtone;
    }

    &:focus,
    &:focus-visible {
      outline: none;
    }
  }

  textarea {
    resize: none;
  }
}

.onair-input__wrapper.onair-input--disabled {
  input {
    background-color: $disabled-bg;
    border-color: $disabled-border-color;
    opacity: 1;
    // Webkit has a thing where it makes disabled inputs look darker.
    // This way it looks the way it was meant to be.
    // The lack of this caused the icon to be washed out by the background color in the disabled state.
    -webkit-opacity: 1;
    -webkit-text-fill-color: $disabled-border-color;
    color: $disabled-border-color;
    &:hover {
      cursor: not-allowed;
    }
  }
}

.onair-input__wrapper.onair-input--error {
  input,
  textarea {
    border-color: $error-midtone;
  }
}

.onair-input__wrapper.onair-input--validated {
  input {
    border-color: $success-midtone;
  }
}

.onair-input__wrapper.onair-input--grow {
  flex-grow: 1;
}

.onair-input-error {
  color: $error-midtone !important;
  padding-left: 24px;
  position: relative; // Have absolute child listen to this
  margin-top: 8px;

  &:before {
    content: '\e93d';
    font-family: $icomoon-font-family;
    font-size: 16px;

    float: left;
    display: block;

    position: absolute;
    left: 0;
  }
}
