.checkbox {
  .checkbox__label {
    display: inline-flex;
    align-items: center;
    cursor: pointer;
    user-select: none;
    border-radius: 1.6px;

    &:hover {
      .checkbox__ghost {
        &:checked {
          + .checkbox__styled {
            background: $primary-dark;
            border-color: $primary-dark;
          }
          &:disabled + .checkbox__styled {
            border-color: $shade-4;
            background-color: $shade-4;
          }
        }
      }

      .checkbox__styled {
        border-color: $shade-3;
        background: $shade-2;
      }
    }
  }

  .checkbox__ghost {
    position: absolute;
    height: 1px;
    width: 1px;
    overflow: hidden;
    clip: rect(1px 1px 1px 1px); /* IE6, IE7 */
    clip: rect(1px, 1px, 1px, 1px);

    &:focus-visible + .checkbox__label {
      outline-offset: 0;
    }

    @supports not selector(:focus-visible) {
      &:focus + .checkbox__label {
        outline-offset: 0;
      }
    }

    &:checked + .checkbox__label > .checkbox__styled {
      background: $primary-midtone;
      border-color: $primary-midtone;

      &::after {
        transform: scale(1);
        transition: transform 0.2s;
      }
    }
    &:disabled + .checkbox__label > .checkbox__styled {
      border-color: $shade-3;
      background-color: $shade-2;
      cursor: not-allowed;
    }

    &:disabled:checked + .checkbox__label > .checkbox__styled {
      border-color: $shade-4;
      background-color: $shade-4;
      cursor: not-allowed;
    }
  }

  .checkbox__styled {
    box-sizing: border-box;
    width: 16px;
    height: 16px;
    border: 2px solid $shade-3;
    border-radius: 1.6px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0; // so the box doesn't re-size as not-a-square
    transition:
      background 0.2s,
      border-color 0.2s;
    margin-right: 16px;

    &::after {
      font-family: $icomoon-font-family;
      content: $oa-check;
      color: $shade-2;
      transform: scale(0);
      font-size: 14px;
      padding-bottom: 1px;
    }

    &:hover {
      border-color: $shade-3;
      background: $shade-2;
    }
  }

  &.checkbox-error {
    .checkbox__styled {
      border-color: $error-midtone;
    }

    &:checked .checkbox__styled {
      border-color: $error-midtone;
      background: $accent-darkest;

      &::after {
        transform: scale(1);
        transition: transform 0.2s;
      }
    }
  }

  &.checkbox-disabled .checkbox__label:hover {
    cursor: not-allowed;
  }
}
