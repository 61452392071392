.past-event-not-purchased-wrapper {
  text-align: center;

  .past-event-buttons {
    display: flex;
    flex-direction: column;

    .past-event-artist-social-media a.socials-share-link {
      &:not(:last-child) {
        margin-right: 24px;
      }

      & > i:before {
        color: $shade-2;
        font-size: 30px;
        line-height: 20px;

        position: relative;
        top: 3px;
      }

      &:focus,
      &:hover {
        i::before {
          color: $shade-white;
        }
      }
    }

    .follow-onair-instagram-btn > .oa-instagram1::before {
      font-size: 30px;
      margin-right: 4px;
      vertical-align: sub;
    }
  }

  .follow-onair-instagram-btn {
    width: 100%;
  }
}

.past-event-purchased-wrapper {
  text-align: center;

  .fb-share-link {
    margin-right: 10%;
  }

  .past-event-social-links {
    font-family: var(--font-dm-sans);
    .socials__link-wrapper {
      justify-content: center;
      a:last-child {
        margin-right: 0;
      }
    }

    a {
      color: $shade-2;
      font-size: 20px;
      font-family: var(--font-poppins);

      &:focus,
      &:hover {
        color: $shade-white;
        i::before {
          color: $shade-white;
        }
      }

      & > i::before {
        color: $shade-2;
        font-size: 25px;
        line-height: 20px;
        position: relative;
        top: 3px;
      }
    }
  }
}
