.event-page__player-wrapper {
  position: relative;
  background-color: $black;
  height: 211px;
}

.contentblock-video-container {
  position: relative;
}

.preview-page-player-wrap {
  flex: 1 100%;
}

.thumbnail-image-container {
  .thumbnail-image {
    z-index: 2;
    background-color: $shade-7;
  }
  .play-icon {
    z-index: 3;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
    border-radius: 50%;
    height: 80px;
    width: 80px;
    border: none;
    background: linear-gradient(
      91.33deg,
      $gradient-orange-begin 0.86%,
      $gradient-orange-end 100%
    );

    i {
      &:before {
        font-size: 28px;
      }
    }
  }
}

@include media-breakpoint-up(sm) {
  .thumbnail-image-container {
    .play-icon {
      height: 96px;
      width: 96px;
      i {
        &:before {
          font-size: 44px;
        }
      }
    }
  }
}

@include media-breakpoint-up(md) {
  .preview-page-player-wrap {
    flex: 1 50%;
  }

  .event-page__player-wrapper {
    height: 432px;
  }

  .thumbnail-image-container {
    .play-icon {
      height: 128px;
      width: 128px;
      i {
        &:before {
          font-size: 60px;
        }
      }
    }
  }
}

@include media-breakpoint-up(lg) {
  .event-page__player-wrapper {
    height: 562px;
  }

  .thumbnail-image-container {
    .play-icon {
      height: 144px;
      width: 144px;
      i {
        &:before {
          font-size: 60px;
        }
      }
    }
  }
}

@include media-breakpoint-up(xl) {
  .event-page__player-wrapper {
    height: 810px;
  }
}
